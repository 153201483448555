import React, { Component } from 'react'
import queryString from 'query-string'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import Toastify from 'toastify-js'
import { Link } from 'react-router-dom'
import Loader from '../../layout/loader'
import Sites from '../../data/models/sites'
import Org from '../../data/models/org'
import done from '../../../images/done-24px.svg'
import clear from '../../../images/clear-24px.svg'
import clearWhite from '../../../images/clear_white-24px.svg'
import republish from '../../../images/republish.svg'
import edit from '../../../images/edit.svg'
import classNames from 'classnames'
import css from '../../../css/dashboard.module.css'
import Button from '../../shared/button'
import { banToast, republishToast } from './helper'

class SitesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sites: [],
      totalCount: 0,
      currentPage: 1,
      itemsPerPage: 50,
      visiblePages: 5,
      isLoading: true,
      searchMode: false,
      isSearching: false,
      filterSearchText: '',
      filterStatus: '',
      filterEngineVersion: '',
      filterIsPublished: '',
      policy: [],
      list: [],
      isStaging: localStorage.isStaging === 'true'
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    console.log('fetching data')
    const { location } = this.props
    const params = queryString.parse(location.search)
    if (location.search !== '') {
      this.searchByParams()
      return
    }

    const pageNumber = this.state.currentPage

    Sites.GetList(pageNumber, data => {
      this.setState({
        sites: data?.sites,
        totalCount: data?.totalCount,
        isLoading: false,
        searchMode: false
      })
    })
  }

  clear = () => {
    this.setState(
      {
        searchText: '',
        filterStatus: '',
        filterEngineVersion: '',
        filterIsPublished: '',
        searchMode: false,
        pageNumber: 1
      },
      () => {
        this.updateQueryParams()
      }
    )

    Sites.GetList(1, data => {
      this.setState({
        sites: data?.sites,
        totalCount: data?.totalCount,
        isLoading: false,
        searchMode: false
      })
    })
  }

  searchByParams = () => {
    const { location } = this.props
    const params = queryString.parse(location.search)

    const query = {}

    if (params.searchText) {
      query.searchText = params.searchText
      this.setState({ searchText: params.searchText })
    }

    if (params.isPublished) {
      query.isPublished = params.isPublished
      this.setState({ filterIsPublished: params.isPublished })
    }

    if (params.engineVersion) {
      query.engineVersion = params.engineVersion
      this.setState({ filterEngineVersion: params.engineVersion })
    }

    if (params.status) {
      query.status = params.status
      this.setState({ filterStatus: params.status })
    }

    this.setState({
      isSearching: true,
      searchText: params.searchText ? params.searchText : '',
      filterStatus: params.status ? params.status : '',
      filterEngineVersion: params.engineVersion ? params.engineVersion : '',
      filterIsPublished: params.isPublished ? params.isPublished : ''
    })

    Sites.GetBySearch(query, data => {
      this.setState({
        isSearching: false,
        sites: data?.sites,
        totalCount: data?.totalCount,
        isLoading: false,
        searchMode: true
      })
    })
  }

  onSearch = () => {
    const query = {}
    const { location } = this.props
    const params = queryString.parse(location.search)

    if (this.state.searchText) {
      query.searchText = this.state.searchText
    }

    if (this.state.filterIsPublished) {
      query.isPublished = this.state.filterIsPublished
    }

    if (this.state.filterEngineVersion) {
      query.engineVersion = this.state.filterEngineVersion
    }

    if (this.state.filterStatus) {
      query.status = this.state.filterStatus
    }

    this.updateQueryParams()

    this.setState({ isSearching: true })

    Sites.GetBySearch(query, data => {
      this.setState({
        isSearching: false,
        sites: data?.sites,
        totalCount: data?.totalCount,
        isLoading: false,
        searchMode: true
      })
    })
  }

  handlePageChange = pageNumber => {
    this.setState({ currentPage: pageNumber }, () => {
      this.fetchData()
    })
  }

  updateQueryParams = () => {
    const { location, history } = this.props
    const query = {
      searchText: this.state.searchText ? this.state.searchText : undefined,
      isPublished: this.state.filterIsPublished ? this.state.filterIsPublished : undefined,
      status: this.state.filterStatus ? this.state.filterStatus : undefined,
      engineVersion: this.state.filterEngineVersion ? this.state.filterEngineVersion : undefined
    }

    const newSearch = queryString.stringify(query)
    history.push({
      pathname: location.pathname,
      search: newSearch
    })
  }

  handleSearchTextFilter = searchText => {
    this.setState({ searchText: searchText })
  }

  handleStatusFilter = status => {
    this.setState({ filterStatus: status })
  }

  handleIsPublishedFilter = isPublished => {
    this.setState({ filterIsPublished: isPublished })
  }

  handleEngineVersionFilter = engineVersion => {
    this.setState({ filterEngineVersion: engineVersion })
  }

  onBan = orgId => {
    Org.BanOrg(orgId, res => (res.success ? setTimeout(() => banToast(), 1000) : null))
  }

  republishSite = id => {
    Sites.RepublishSite(id, res => (res.success ? republishToast() : null))
  }

  republishAllOrgSites = id => {
    Org.RepublishAllOrgSites(id, res => (res.success ? republishToast() : null))
  }

  onKeyUp = e => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.onSearch(this.state.searchText)
    }
  }

  render() {
    const {
      sites,
      isStaging,
      itemsPerPage,
      totalCount,
      currentPage,
      visiblePages,
      searchText,
      filterStatus,
      filterIsPublished,
      filterEngineVersion,
      isSearching,
      searchMode,
      isLoading
    } = this.state

    const totalPages = Math.ceil(totalCount / itemsPerPage)
    const startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
    const endPage = Math.min(totalPages, startPage + visiblePages - 1)
    const stagingBorder = isStaging ? '5px solid #CF55A4' : ''
    const currentItems = sites || []

    const Pagination = ({ state, handlePageChange }) => (
      <div className={css.pagination}>
        <div className={css.pagination}>
          <button
            disabled={state.currentPage === 1}
            onClick={() => handlePageChange(state.currentPage - 1)}
          >
            Previous
          </button>

          {startPage > 1 && <button onClick={() => handlePageChange(1)}>1</button>}
          {startPage > 2 && <span>...</span>}

          {Array.from({ length: endPage - startPage + 1 }, (_, i) => (
            <button
              key={i + startPage}
              onClick={() => this.handlePageChange(i + startPage)}
              className={classNames({
                [css.currentPage]: state.currentPage === i + startPage
              })}
            >
              {i + startPage}
            </button>
          ))}

          {endPage < totalPages - 1 && <span>...</span>}
          {endPage < totalPages && (
            <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
          )}

          <button
            disabled={state.currentPage === totalPages}
            onClick={() => handlePageChange(state.currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    )

    const TableContent = ({ state, currentItems, republishSite, onBan, republishAllOrgSites }) => (
      <div className={css.table}>
        <AutoSizer>
          {({ width, height }) => (
            <Table
              width={width}
              height={height}
              headerHeight={40}
              rowHeight={50}
              rowCount={currentItems.length} // Change to currentItems.length
              rowGetter={({ index }) => currentItems[index]} // Change to currentItems[index]
              headerClassName={css.header}
              // define index even for every second row - "zebralook"
              rowClassName={({ index }) => {
                if (index % 2 === 0) {
                  return 'even'
                }
              }}
            >
              <Column
                label="Edit"
                dataKey="id"
                width={40}
                cellRenderer={({ rowData }) => {
                  const editPath = rowData.engineVersion === 2 ? '/next' : '/edit'
                  return (
                    <a
                      href={
                        state.isStaging
                          ? 'https://app-staging.umso.com/?assumeOrgId=' +
                            rowData.orgId +
                            '&path=/sites/' +
                            rowData.id +
                            editPath
                          : 'https://app.umso.com/?assumeOrgId=' +
                            rowData.orgId +
                            '&path=sites/' +
                            rowData.id +
                            editPath
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={edit} alt="done" />
                    </a>
                  )
                }}
              />
              <Column
                label="Published"
                dataKey="isPublished"
                cellRenderer={({ cellData }) =>
                  //if cellData is available show done image
                  cellData ? (
                    <img src={done} alt="done" />
                  ) : (
                    //else show clear image
                    <img src={clear} alt="clear" />
                  )
                }
                width={40}
              />

              <Column
                label="status"
                dataKey="isPublished"
                cellRenderer={({ rowData }) =>
                  rowData.status === 'free' ? (
                    <span className={css.freeStatusBadge}>Free</span>
                  ) : (
                    <span className={css.proStatusBadge}>Pro</span>
                  )
                }
                width={70}
              />
              <Column
                label="URL"
                dataKey="domain"
                width={350}
                cellRenderer={({ rowData }) => {
                  const defaultDomain = rowData.domains.find(domain => {
                    if (domain.variation === 'default') {
                      if (!domain.redirect) {
                        return domain
                      }
                    }
                    return null
                  })

                  let domain
                  let subdomain

                  if (defaultDomain?.domain) {
                    domain = defaultDomain.domain
                  } else {
                    subdomain = defaultDomain?.subdomain + defaultDomain?.subdomainSuffix
                  }

                  if (domain) {
                    return (
                      <div className={css.republishWrapper}>
                        <a href={'https://' + domain} target="_blank" rel="noopener noreferrer">
                          {domain}
                        </a>
                        <div onClick={() => republishSite(rowData.id)}>
                          <img src={republish} alt="republish" />
                        </div>
                      </div>
                    )
                  }
                  if (subdomain) {
                    return (
                      <div className={css.republishWrapper}>
                        <a href={'https://' + subdomain} target="_blank" rel="noopener noreferrer">
                          {subdomain}
                        </a>
                        <div onClick={() => republishSite(rowData.id)}>
                          <img src={republish} alt="republish" />
                        </div>
                      </div>
                    )
                  }
                }}
              />
              <Column
                label="version"
                dataKey="engineVersion"
                cellRenderer={({ cellData }) => <p>{cellData === 2 ? 'Umso X' : 'Legacy'}</p>}
                width={90}
              />
              <Column
                label="siteId"
                dataKey="id"
                cellRenderer={({ cellData }) => <p>{cellData}</p>}
                width={190}
              />
              <Column
                label="Org"
                dataKey="orgName"
                width={260}
                //use rowData to get more information of the same level!
                cellRenderer={({ rowData }) => (
                  <div className={css.orgWrapper}>
                    <button className={css.banButton} onClick={() => onBan(rowData.orgId)}>
                      <img src={clearWhite} alt="clear" />
                    </button>
                    <a href={'/orgs/' + rowData.orgId} target="_blank" rel="noopener noreferrer">
                      {rowData.orgName || rowData.orgId}
                    </a>
                    <div onClick={() => republishAllOrgSites(rowData.orgId)}>
                      <img src={republish} alt="republish" />
                    </div>
                  </div>
                )}
              />
              <Column
                label="Creator"
                dataKey="createdBy"
                //link to Id but show email
                cellRenderer={({ rowData }) => (
                  <Link to={'/users?search=' + rowData.createdBy.email}>
                    {rowData.createdBy.email || 'na'}
                  </Link>
                )}
                width={220}
              />

              <Column label="Pages" dataKey="pageCount" width={70} />

              <Column
                label="Analytics"
                dataKey="advancedAnalytics"
                cellRenderer={({ cellData }) =>
                  cellData ? <img src={done} alt="done" /> : <img src={clear} alt="clear" />
                }
                width={90}
              />

              <Column
                label="Last Published"
                dataKey="lastPublished"
                cellRenderer={({ cellData }) =>
                  //format the date to absolute last publish date
                  cellData && formatDistanceToNowStrict(parseISO(cellData))
                }
                width={130}
              />
              <Column
                label="Creation Date"
                dataKey="createdAt"
                //format the date to relative date
                cellRenderer={({ cellData }) =>
                  cellData && formatDistanceToNowStrict(parseISO(cellData))
                }
                width={130}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    )

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <div className={css.searchForm}>
          <div className={css.row}>
            <input
              className={css.searchFormField}
              type="text"
              placeholder="Search..."
              onChange={e => this.handleSearchTextFilter(e.target.value)}
              value={searchText}
              onKeyUp={this.onKeyUp}
            />
            <select
              value={filterStatus}
              className={css.searchFormField}
              onChange={e => this.handleStatusFilter(e.target.value)}
            >
              <option value="">Pro & Free</option>
              <option value="pro">Pro</option>
              <option value="free">Free</option>
            </select>
            <select
              value={filterIsPublished}
              className={css.searchFormField}
              onChange={e => this.handleIsPublishedFilter(e.target.value)}
            >
              <option value="">Published & Unpublished</option>
              <option value="true">Published</option>
              <option value="false">Unpublished</option>
            </select>
            <select
              value={filterEngineVersion}
              className={css.searchFormField}
              onChange={e => this.handleEngineVersionFilter(e.target.value)}
            >
              <option value="">Any Version</option>
              <option value="2">Editor X</option>
              <option value="1">Legacy Editor</option>
            </select>
            <div className={css.buttons}>
              <Button
                className={css.actionButton}
                primary
                large
                onClick={() => this.onSearch(searchText)}
                loading={isSearching}
              >
                Search
              </Button>
              <Button className={css.actionButton} secondary large onClick={this.clear}>
                Clear
              </Button>
            </div>
          </div>
          <div className={css.row}>
            <span className={css.results}>Results: {this.state.totalCount} Sites</span>
          </div>
        </div>
        {!searchMode && <Pagination state={this.state} handlePageChange={this.handlePageChange} />}
        {isLoading ? (
          <Loader />
        ) : (
          <TableContent
            state={this.state}
            currentItems={currentItems}
            republishSite={this.republishSite}
            onBan={this.onBan}
            republishAllOrgSites={this.republishAllOrgSites}
          />
        )}
      </div>
    )
  }
}

export default SitesList
