import API from "../api";


export default class ModerationList {
    static GetModeration = (cb) => {
        API.Get({
            path: "/admin/moderation/resources",
            success: cb,
            error: cb,
        });
    };

    static MarkHarmless = (data, cb) => {
        if (window.confirm(`Is "${data.resourceId}" really legit?`)) {
            API.Post({
                path: "/admin/moderation/resources",
                data: {
                    "resourceId": data.resourceId,
                    "resourceType": data.resourceType,
                    "status": "harmless",
                    "data": data.data,
                    "ownedBy": data.ownedBy,
                },
                success: cb,
                error: cb,
            });
        } else {
            return null;
        }
    }
}
