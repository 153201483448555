import React, { Component } from 'react'
import Loader from '../../layout/loader'
import Pages from '../../data/models/pages'
import css from './pages.module.css'

class PagesScreenshots extends Component {
  constructor(props) {
    super(props)

    this.state = {
      screenshots: [],
      nextPageScreenshots: [],
      previousPages: {},
      isLoading: false,
      currentPageNumber: 1,
      hasNextPage: false,
      showModal: false,
      selectedScreenshotUrl: ''
    }
  }

  componentDidMount() {
    this.loadPage(this.state.currentPageNumber)
  }

  handleOpenModal = url => {
    this.setState({ showModal: true, selectedScreenshotUrl: url })
  }

  handleCloseModal = () => {
    this.setState({ showModal: false })
  }

  handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      this.handleCloseModal()
    }
  }

  loadPage(pageNumber) {
    this.setState({ isLoading: true })

    Pages.GetRecentScreenshots(pageNumber, res => {
      if (!res || res.error) {
        this.setState({ isLoading: false })
        return
      }

      this.setState(prevState => ({
        screenshots: res,
        isLoading: false,
        previousPages: {
          ...prevState.previousPages,
          [pageNumber]: res
        }
      }))

      this.loadNextPage(pageNumber + 1)
    })
  }

  loadNextPage(pageNumber) {
    Pages.GetRecentScreenshots(pageNumber, res => {
      if (!res || res.error) {
        this.setState({
          hasNextPage: false
        })
      }

      this.setState(prevState => ({
        nextPageScreenshots: res,
        hasNextPage: !!res.length,
        previousPages: {
          ...prevState.previousPages,
          [pageNumber]: res
        }
      }))
    })
  }

  goToNextPage() {
    const { currentPageNumber, previousPages, nextPageScreenshots } = this.state
    const nextPageNumber = currentPageNumber + 1
    const nextSavedPage = previousPages[nextPageNumber]

    if (nextSavedPage?.length > 0) {
      const hasFollowingPageSaved = previousPages[currentPageNumber + 2]?.length > 0

      this.setState(
        {
          screenshots: nextSavedPage,
          currentPageNumber: nextPageNumber,
          hasNextPage: hasFollowingPageSaved
        },
        () => {
          if (!hasFollowingPageSaved) {
            this.loadNextPage(this.state.currentPageNumber + 1)
          }
        }
      )

      return
    }

    this.setState(
      {
        screenshots: nextPageScreenshots,
        currentPageNumber: nextPageNumber
      },
      () => {
        this.loadNextPage(nextPageNumber)
      }
    )
  }

  goToPreviousPage() {
    const { currentPageNumber, previousPages } = this.state

    if (currentPageNumber > 1) {
      const previousPageNumber = currentPageNumber - 1
      this.setState(() => {
        const previousPageScreenshots = previousPages[previousPageNumber]

        return {
          screenshots: previousPageScreenshots,
          currentPageNumber: previousPageNumber,
          hasNextPage: true
        }
      })
    }
  }

  renderScreenshots() {
    const { screenshots } = this.state
    return screenshots.map(screenshot => {
      const url = `https://admin.umso.com/sites?searchText=${screenshot.siteId}`
      return (
        <img
          src={screenshot.url}
          onClick={e => {
            if (e.metaKey || e.ctrlKey) {
              window.open(url, '_blank')
            } else {
              this.handleOpenModal(screenshot.url)
            }
          }}
          alt="screenshot"
          className={css.screenshot}
        />
      )
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    return (
      <div className={css.screenshotWrapper}>
        <div className={css.headerWrapper}>
          <div className={css.pageNumber}>Page {this.state.currentPageNumber}</div>
          <div className={css.navigationButtons}>
            <button
              onClick={() => this.goToPreviousPage()}
              className={css.navigationButton}
              disabled={this.state.currentPageNumber <= 1}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <button
              onClick={() => this.goToNextPage()}
              className={css.navigationButton}
              disabled={!this.state.hasNextPage}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div className={css.screenshotContainer}>{this.renderScreenshots()}</div>
        {this.state.showModal && (
          <div className={css.modalOverlay} onClick={this.handleOverlayClick}>
            <div className={css.modalContent} onClick={e => e.stopPropagation()}>
              <img src={this.state.selectedScreenshotUrl} alt="Open Screenshot" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default PagesScreenshots
