import API from '../api'

export default class KeywordsList {
  static GetKeywords = cb => {
    API.Get({
      path: '/admin/moderation/blocklist',
      success: cb,
      error: cb
    })
  }

  static CreateKeyword = (data, cb) => {
    API.Post({
      path: '/admin/moderation/blocklist',
      data: data,
      success: cb,
      error: cb
    })
  }

  static DeleteKeyword = (id, cb) => {
    API.Delete({
      path: '/admin/moderation/blocklist/' + id,
      success: cb,
      error: cb
    })
  }

  static ScanAll = (id, data, cb) => {
    API.Post({
      path: '/admin/moderation/blocklist/' + id + '/scan',
      data: data,
      success: cb,
      error: cb
    })
  }
}
