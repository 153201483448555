import React, { Component } from 'react'
import { parseISO, format } from 'date-fns'
import queryString from 'query-string'
import { Column, Table, AutoSizer } from 'react-virtualized'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'

import Lists from '../../data/models/lists'
import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'

import css from '../../../css/dashboard.module.css'

//options from fuse
const options = {
  threshold: 0.1,
  //keys that fuse filters in the search
  keys: ['name', 'createdBy.email', 'orgName', 'orgId', 'id']
}

class Libraries extends Component {
  state = {
    lists: [],
    isLoading: true,
    fuse: null
  }

  componentDidMount() {
    //get data from API
    Lists.GetList(data => {
      const params = queryString.parse(this.props.location.search)

      // initialize fuse because we need the data from blogs
      const fuse = new Fuse(data, options)
      this.setState({
        lists: data,
        isLoading: false,
        value: params.search || '',
        fuse: fuse
      })
    })
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    //"create new data" from the fuse search
    var data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(lists => lists.item)
    } else {
      data = this.state.lists
    }

    return (
      <div className={css.dashboard}>
        <Searchbar
          length={data.length}
          onSearch={this.onSearch}
          value={this.state.value}
        />
        <div className={css.table}>
          {/* get maximal height and width */}
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                // define index even for every secon row - "zebralook"
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="Edit"
                  dataKey="id"
                  width={60}
                  cellRenderer={({ cellData }) => (
                    // a for external links with href
                    <a
                      href={'https://app.umso.com/lists/' + cellData}
                      //new tab
                      target="_blank"
                      //react wants it to be safe
                      rel="noopener noreferrer"
                    >
                      Edit
                    </a>
                  )}
                />
                <Column
                  label="Name"
                  dataKey="name"
                  width={300}
                  //use rowData to get more information of the same level!
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                />
                <Column
                  label="Id"
                  dataKey="id"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={250}
                />
                <Column
                  label="Creator"
                  dataKey="createdBy"
                  //create new array with members id and then show the mail
                  cellRenderer={({ rowData }) => (
                    <Link to={'/users?search=' + rowData.createdBy.email}>
                      {rowData.createdBy.email || 'na'}
                    </Link>
                  )}
                  width={300}
                />
                <Column
                  label="Org"
                  dataKey="orgName"
                  width={250}
                  //use rowData to get more information of the same level!
                  cellRenderer={({ rowData }) => (
                    <Link to={'/orgs/' + rowData.orgId}>
                      {rowData.orgName || rowData.orgId}
                    </Link>
                  )}
                />
                <Column
                  label="created at"
                  dataKey="createdAt"
                  //format the date to absolute date
                  cellRenderer={({ cellData }) =>
                    format(parseISO(cellData), 'yyyy-MM-dd HH:mm')
                  }
                  width={250}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}
export default Libraries
