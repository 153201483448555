import React, { Component } from 'react'
import { AutoSizer, Column, Table } from 'react-virtualized'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import Users from '../../data/models/users'
import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'

import done from '../../../images/done-24px.svg'
import clear from '../../../images/clear-24px.svg'

import css from '../../../css/dashboard.module.css'

//options from fuse
const options = {
  threshold: 0.01,
  //keys that fuse filters in the search - usually email!
  keys: ['name', 'id', 'email', 'orgs.id', 'orgs.name']
}

class UserList extends Component {
  state = {
    users: [],
    isLoading: true,
    fuse: null,
    checked: false,
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    //get data from API
    Users.GetList(data => {
      const params = queryString.parse(this.props.location.search)
      // initialize fuse because we need the data from users
      const fuse = new Fuse(data, options)
      this.setState({
        users: data,
        isLoading: false,
        value: params.search || '',
        fuse: fuse
      })
    })
  }

  handleFilter = () => {
    if (this.state.checked === false) {
      this.setState({ checked: true })
    } else {
      this.setState({ checked: false })
    }
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    //"create new data" from the fuse search
    var data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(user => user.item)
    } else {
      data = this.state.users
    }

    if (this.state.checked === true) {
      var filteredUsers = data
      filteredUsers = filteredUsers.map(element =>
        element.isVerified === true ? element : 'unverified'
      )

      function checkIfVerified(item) {
        return item !== 'unverified'
      }

      data = filteredUsers.filter(checkIfVerified)
    }

    const stagingBorder = this.state.isStaging ? '5px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value}>
          <div className={css.filterCheckbox}>
            <input type="checkbox" onClick={this.handleFilter} id="checkbox" />
            <label htmlFor="checkbox">verified</label>
          </div>
        </Searchbar>

        <div className={css.table}>
          {/* get maximal height and width */}
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                // define index even for every secon row - "zebralook"
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="id"
                  dataKey="id"
                  width={80}
                  cellRenderer={({ cellData }) => {
                    return <p>{cellData}</p>
                  }}
                />
                <Column label="Name" dataKey="name" width={200} />
                <Column
                  label="email"
                  dataKey="email"
                  width={350}
                  //use rowData to get more information of the same level!
                  cellRenderer={({ rowData }) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={'https://umso.zendesk.com/agent/tickets/new/1'}
                    >
                      {rowData.email}
                    </a>
                  )}
                />
                <Column
                  label="Stripe-Id"
                  dataKey="email"
                  width={200}
                  cellRenderer={({ cellData }) => (
                    // a for external links with href
                    <a
                      href={'https://dashboard.stripe.com/search?query=' + cellData}
                      //new tab
                      target="_blank"
                      //react wants it to be safe
                      rel="noopener noreferrer"
                    >
                      Stripe
                    </a>
                  )}
                />
                <Column
                  label="Verified"
                  dataKey="isVerified"
                  cellRenderer={({ cellData }) =>
                    //if cellData is available show done image
                    cellData ? (
                      <img src={done} alt="done" />
                    ) : (
                      //else show clear image
                      <img src={clear} alt="clear" />
                    )
                  }
                  width={100}
                />
                <Column
                  label="OrgId"
                  dataKey="orgs"
                  //go  more down in the data with .map() and display the name as a link
                  cellRenderer={({ cellData }) =>
                    cellData.map(org => <Link to={'/orgs/' + org.id}>{org.name || org.id}</Link>)
                  }
                  width={250}
                />
                <Column
                  label="Sign up"
                  dataKey="createdAt"
                  //format the date to relative date
                  cellRenderer={({ cellData }) => formatDistanceToNowStrict(parseISO(cellData))}
                  width={100}
                />

                <Column
                  dataKey="createdAt"
                  //format the date to absolute date
                  cellRenderer={({ cellData }) => format(parseISO(cellData), 'yyyy-MM-dd HH:mm')}
                  width={200}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default UserList
