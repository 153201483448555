import API from '../api'

import cache from '../cache'

export default class Pages {
  static GetRecentScreenshots = (pageNumber, cb) => {
    const cacheKey = `pages-screenshots-${pageNumber}`
    const cached = cache.Get(cacheKey)

    if (cached && cached.length) {
      cb(cached)
      return
    }

    API.Get({
      path: `/admin/pages-screenshots?page=${pageNumber}`,
      success: res => {
        if (res && res.length) {
          cache.Set(cacheKey, res)
        }
        cb(res)
      },
      error: cb
    })
  }
}
