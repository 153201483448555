import React, { Component } from 'react'
import { AutoSizer, Column, Table } from 'react-virtualized'
import Toastify from 'toastify-js'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import Fuse from 'fuse.js'
import queryString from 'query-string'
import Select from 'react-select'

import ModerationList from '../../data/models/moderation'
import Org from '../../data/models/org'
import Orgs from '../../data/models/orgs'
import KeywordsList from '../../data/models/keywords'
import DatePickerScan from './datePickerScan'

import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'

import css from '../../../css/dashboard.module.css'

import done from '../../../images/done-white.svg'
import clearWhite from '../../../images/clear_white-24px.svg'

const options = {
  threshold: 0.1,
  keys: ['data.keywords']
}

const blockNotice = `This item level is set to block. This will automatically ban all organizations that uses this restricted item. Are you sure you want to continue?`

class Moderation extends Component {
  state = {
    moderation: [],
    orgs: [],
    isLoading: true,
    selectOption: 'pending',
    keywords: [],
    filterHarmless: true,
    selectedStatus: true,
    selectedKeyword: {},
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    ModerationList.GetModeration(data => {
      if (!data) {
        data = []
      }

      const params = queryString.parse(this.props.location.search)
      const fuse = new Fuse(data, options)

      Orgs.GetList(orgsData => {
        this.setState({
          moderation: data,
          orgs: orgsData,
          isLoading: false,
          value: params.search || '',
          fuse: fuse
        })
      })
    })

    KeywordsList.GetKeywords(data => {
      this.setState({
        keywords: data
      })
    })
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  handleSelect = e => {
    this.setState({ selectedKeyword: e.target.value })
  }

  handleModerationSelect = e => {
    this.setState({ selectOption: e.target.value })
  }

  onBan = orgId => {
    Org.BanOrg(orgId, res =>
      res.success
        ? setTimeout(() => {
            this.banToast()
            ModerationList.GetModeration(data => {
              this.setState({
                moderation: data,
                isLoading: false
              })
            })
          }, 1000)
        : null
    )
  }

  onUnban = orgId => {
    Org.UnbanOrg(orgId, res =>
      res.success
        ? setTimeout(() => {
            this.banToast()
            ModerationList.GetModeration(data => {
              this.setState({
                moderation: data,
                isLoading: false
              })
            })
          }, 1000)
        : null
    )
  }

  onLegit = data => {
    ModerationList.MarkHarmless(data, res =>
      res.success
        ? setTimeout(() => {
            this.updatedToast()
            ModerationList.GetModeration(data => {
              this.setState({
                moderation: data,
                isLoading: false
              })
            })
          }, 1000)
        : null
    )
  }

  updatedToast = () => {
    Toastify({
      text: 'Updated!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'rgb(57, 118, 255)'
    }).showToast()
  }

  banToast = () => {
    Toastify({
      text: 'Org Banned!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'red'
    }).showToast()
  }

  unbanToast = () => {
    Toastify({
      text: 'Org Unbanned!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'green'
    }).showToast()
  }

  successToast = () => {
    Toastify({
      text: 'Re-Scan successful!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'rgb(57, 118, 255)'
    }).showToast()
  }

  errorToast = () => {
    Toastify({
      text: 'Error!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'red'
    }).showToast()
  }

  handleSelectKeyword = val => {
    this.setState({
      selectedKeyword: val
    })
  }

  handleSelectPublished = e => {
    if (e.target.value === 'true') {
      this.setState({ selectedStatus: true })
    }
    if (e.target.value === 'false') {
      this.setState({ selectedStatus: false })
    }
  }

  scanForBlocklistItem = (start, end) => {
    let keyword = this.state.selectedKeyword.value

    let item = this.state.keywords.find(k => k.item === keyword)

    if (!item) {
      this.errorToast()
      return
    }

    if (item.level === 'block') {
      if (!window.confirm(blockNotice)) {
        return
      }
    }

    if (window.confirm(`You really want to scan sites, pages and blogs for this keyword?`)) {
      const data = {
        start: start,
        end: end,
        published: this.state.selectedStatus
      }

      KeywordsList.ScanAll(item.id, data, response => {
        if (!response.error) {
          this.successToast()
        } else {
          this.errorToast()
        }
      })
    }
    //update moderation list
    window.location.reload()
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    let data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(keywords => keywords.item)
    } else {
      data = this.state.moderation
    }

    // filter for selection
    if (this.state.selectOption === 'pending') {
      data = data.filter(item => (item.status === 'harmless' ? null : item))
      let filteredList = data
      filteredList = filteredList.map(element =>
        element.isOrgBanned !== true ? element : 'not banned'
      )

      function checkIfBanned(item) {
        return item !== 'not banned'
      }

      data = filteredList.filter(checkIfBanned)
    }

    if (this.state.selectOption === 'harmless') {
      data = data.filter(item => (item.status !== 'harmless' ? null : item))
    }

    if (this.state.selectOption === 'banned') {
      let filteredList = data
      filteredList = filteredList.map(element =>
        element.isOrgBanned === true ? element : 'banned'
      )

      function checkIfBanned(item) {
        return item !== 'banned'
      }

      data = filteredList.filter(checkIfBanned)
    }

    const keywordOptions = this.state.keywords?.map(keyword => ({
      value: keyword.item,
      label: keyword.item
    }))

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value}>
          <div className={css.filterCheckbox}>
            <label htmlFor="select">show</label>
            <select className={css.selectFilter} onChange={this.handleModerationSelect}>
              <option>pending</option>
              <option>harmless</option>
              <option>banned</option>
            </select>
          </div>
        </Searchbar>
        <div className={css.keywordWrapper}>
          <h3>Re-Scan</h3>
          <label className={css.label}>Keyword: </label>
          <Select
            className={css.reactSelect}
            onChange={val => this.handleSelectKeyword(val)}
            value={this.state.selectedKeyword}
            options={keywordOptions}
          />
          <label className={css.label}>Published: </label>
          <select
            className={css.selectFilter}
            onChange={this.handleSelectPublished}
            value={this.state.selectedStatus}
          >
            <option value={true}>true</option>
            <option value={false}>false</option>
          </select>
          <DatePickerScan reScan={this.scanForBlocklistItem} />
        </div>
        <div className={css.table}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="Status"
                  dataKey="status"
                  width={150}
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                />
                <Column
                  label="matches"
                  dataKey="data"
                  width={300}
                  cellRenderer={({ cellData }) => {
                    if (cellData.keywords) {
                      let keywords = cellData.keywords.map(keyword =>
                        keyword.charAt(0) === '\\' ? keyword.slice(2, -2) : keyword
                      )
                      keywords = [...new Set(keywords)].join(', ')
                      return <p>{keywords}</p>
                    } else {
                      return
                    }
                  }}
                />
                <Column
                  label="resId"
                  dataKey="resourceId"
                  cellRenderer={({ rowData }) => {
                    return (
                      <div className={css.orgWrapper}>
                        {rowData.isOrgBanned === true ? (
                          <>
                            {/*TODO: make the hrefs objects*/}
                            {rowData.resourceType === 'site' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fe001b' }}
                              >
                                {rowData.resourceId.slice(0, 13)}
                              </a>
                            ) : rowData.resourceType === 'page' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fe001b' }}
                              >
                                {rowData.resourceId.slice(0, 18)}
                              </a>
                            ) : rowData.resourceType === 'blog_post' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fe001b' }}
                              >
                                {rowData.resourceId.slice(0, 4)}
                              </a>
                            ) : rowData.resourceType === 'library_file' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fe001b' }}
                              >
                                {rowData.resourceId.slice(0, 3)}
                              </a>
                            ) : rowData.resourceType === 'form' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#fe001b' }}
                              >
                                {rowData.resourceId.slice(5, 22)}
                              </a>
                            ) : null}
                          </>
                        ) : rowData.status !== 'harmless' ? (
                          <>
                            <div className={css.tooltip}>
                              <span className={css.tooltiptext}>Mark harmless.</span>
                              <button
                                className={css.legitButton}
                                onClick={() => this.onLegit(rowData)}
                              >
                                <img src={done} alt="done" />
                              </button>
                            </div>
                            {rowData.resourceType === 'site' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.resourceId.slice(0, 13)}
                              </a>
                            ) : rowData.resourceType === 'page' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.resourceId.slice(0, 18)}
                              </a>
                            ) : rowData.resourceType === 'blog_post' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.resourceId.slice(0, 4)}
                              </a>
                            ) : rowData.resourceType === 'library_file' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.resourceId.slice(0, 3)}
                              </a>
                            ) : rowData.resourceType === 'form' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {rowData.resourceId.slice(5, 22)}
                              </a>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {rowData.resourceType === 'site' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#00fe65' }}
                              >
                                {rowData.resourceId.slice(0, 13)}
                              </a>
                            ) : rowData.resourceType === 'page' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/sites/' +
                                      rowData.resourceId.slice(0, 13) +
                                      '/edit'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#00fe65' }}
                              >
                                {rowData.resourceId.slice(0, 18)}
                              </a>
                            ) : rowData.resourceType === 'blog_post' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/blogs/' +
                                      rowData.resourceId.slice(0, 21)
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#00fe65' }}
                              >
                                {rowData.resourceId.slice(0, 4)}
                              </a>
                            ) : rowData.resourceType === 'library_file' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/libraries/' +
                                      rowData.resourceId
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#00fe65' }}
                              >
                                {rowData.resourceId.slice(0, 3)}
                              </a>
                            ) : rowData.resourceType === 'form' ? (
                              <a
                                href={
                                  this.state.isStaging
                                    ? 'https://app-staging.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                    : 'https://app.umso.com/?assumeOrgId=' +
                                      rowData.ownedBy +
                                      '&path=/forms/' +
                                      rowData.resourceId +
                                      '/responses'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: '#00fe65' }}
                              >
                                {rowData.resourceId.slice(5, 22)}
                              </a>
                            ) : null}
                          </>
                        )}
                      </div>
                    )
                  }}
                  width={250}
                />
                <Column
                  label="resType"
                  dataKey="resourceType"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={100}
                />
                <Column
                  label="ProSites"
                  dataKey="ownedBy"
                  width={120}
                  cellRenderer={({ cellData }) => {
                    let limit = 0
                    let org = this.state.orgs
                      ? [...this.state.orgs].filter(org => org.id === cellData)
                      : []
                    limit = org[0]?.policy?.siteLimit + org[0]?.policy?.extraSites
                    return <p>{limit}</p>
                  }}
                />
                <Column
                  label="OrgId"
                  dataKey="ownedBy"
                  width={300}
                  cellRenderer={({ rowData }) => {
                    return (
                      <div className={css.orgWrapper}>
                        {rowData.isOrgBanned !== true ? (
                          <>
                            <div className={css.tooltip}>
                              <span className={css.tooltiptext}>Ban this Org.</span>
                              <button
                                className={css.banButton}
                                onClick={() => this.onBan(rowData.ownedBy)}
                              >
                                <img src={clearWhite} alt="clear" />
                              </button>
                            </div>
                            <a
                              href={'/orgs/' + rowData.ownedBy}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {rowData.ownedBy}
                            </a>
                          </>
                        ) : (
                          <>
                            <div className={css.banButtonPlaceholder} />
                            <a
                              href={'/orgs/' + rowData.ownedBy}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'rgb(159,31,31)' }}
                            >
                              {rowData.ownedBy}
                            </a>
                          </>
                        )}
                      </div>
                    )
                  }}
                />
                <Column
                  label="modId"
                  dataKey="id"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={120}
                />
                <Column
                  label="Last Updated"
                  dataKey="updatedAt"
                  cellRenderer={({ cellData }) => format(parseISO(cellData), 'yyyy-MM-dd HH:mm')}
                  width={220}
                />
                <Column
                  label=""
                  dataKey="createdAt"
                  cellRenderer={({ cellData }) => formatDistanceToNowStrict(parseISO(cellData))}
                  width={100}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default Moderation
