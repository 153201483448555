import React, { Component } from 'react'
import { AutoSizer, Column, Table } from 'react-virtualized'
import queryString from 'query-string'
import Fuse from 'fuse.js'
import { CheckIcon, TrashIcon } from '@radix-ui/react-icons'
import 'react-datepicker/dist/react-datepicker.css'
import Toastify from 'toastify-js'

import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'
import ProductUpdateAPI from '../../data/models/productUpdate'
import TimeAgo from '../../shared/timeago'
import css from './product-updates.module.css'
import DatePicker from 'react-datepicker'

const options = {
  threshold: 0.1,
  keys: ['id', 'feature', 'title', 'description', 'status', 'createdAt', 'updatedAt']
}

class ProductUpdates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      changelogs: [],
      isLoading: true,
      title: '',
      description: '',
      category: '',
      status: 'planned',
      isStaging: localStorage.isStaging === 'true',
      editableRowId: null,
      editableField: null,
      editableValue: '',
      originalValue: ''
    }
  }

  componentDidMount() {
    this.getAll()
  }

  getAll = () => {
    ProductUpdateAPI.GetAll(data => {
      if (!data) {
        data = []
      }

      const params = queryString.parse(this.props.location.search)
      const fuse = new Fuse(data, options)

      this.setState({
        changelogs: data,
        isLoading: false,
        value: params.search || '',
        fuse: fuse
      })
    })
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  onKeyUp = e => {
    if (e.key === 'Enter') {
      this.handleAddChangelog()
    }
  }

  onKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleUpdate()
    }
    if (event.key === 'Escape') {
      this.discardChanges()
    }
  }

  onClick = (id, field, value) => {
    this.setState({
      editableRowId: id,
      editableField: field,
      editableValue: value,
      originalValue: value
    })
  }

  onChange = e => {
    this.setState({
      editableValue: e.target.value
    })
  }

  onUpdatedAtChange = date => {
    this.setState({
      editableValue: date
    })
  }

  discardChanges = () => {
    this.setState({
      editableRowId: null,
      editableField: null,
      editableValue: this.state.originalValue,
      originalValue: ''
    })
  }

  successToast = () => {
    Toastify({
      text: 'Keywords updated!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'rgb(57, 118, 255)'
    }).showToast()
    this.getAll()
  }

  errorToast = err => {
    Toastify({
      text: err,
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'red'
    }).showToast()
  }

  handleAddChangelog = () => {
    const { title, description, category, status } = this.state

    if (title && description && category && status) {
      const data = {
        title: title,
        description: description,
        category: category,
        status: status
      }

      ProductUpdateAPI.Create(data, response => {
        if (!response.error) {
          let newChangelogs = [...this.state.changelogs]
          newChangelogs.push(data)
          this.setState({
            title: '',
            description: '',
            category: '',
            status: 'planned'
          })
          this.successToast()
        } else {
          this.errorToast(response.error)
        }
      })
    } else {
      this.errorToast('Fields are required!')
    }
  }

  handleDelete = id => {
    let item = this.state.changelogs.find(k => k.id === id)

    if (!item) {
      this.errorToast('Please select an item to delete')
      return
    }

    if (window.confirm('Are you sure?')) {
      ProductUpdateAPI.Delete(id, response => {
        if (!response.error) {
          let newChangelogs = this.state.changelogs.filter(k => k.id !== item.id)
          this.setState({ changelogs: newChangelogs })
          this.successToast()
        } else {
          this.errorToast(response.error)
        }
      })
    }
  }

  handleUpdate = () => {
    let { editableRowId, editableField, editableValue, changelogs } = this.state

    if (editableField === 'updatedAt') {
      editableValue = new Date(editableValue).toISOString()
    }

    let updatingRow = this.state.changelogs.find(k => k.id === editableRowId)

    const data = {
      title: editableField === 'title' ? editableValue : updatingRow.title,
      description: editableField === 'description' ? editableValue : updatingRow.description,
      category: editableField === 'category' ? editableValue : updatingRow.category,
      status: editableField === 'status' ? editableValue : updatingRow.status,
      updatedAt: editableField === 'updatedAt' ? editableValue : updatingRow.updatedAt
    }

    ProductUpdateAPI.Update(editableRowId, data, response => {
      if (!response.error) {
        this.getAll()
        this.successToast()
      } else {
        this.errorToast(response.error)
      }
    })

    const updatedChangelogs = changelogs.map(changelog =>
      changelog.id === editableRowId ? { ...changelog, [editableField]: editableValue } : changelog
    )

    this.setState({
      changelogs: updatedChangelogs,
      editableRowId: null,
      editableField: null,
      editableValue: '',
      originalValue: ''
    })
  }

  statuses = [
    { key: 'planned', value: 'Planned' },
    { key: 'inProgress', value: 'In Progress' },
    { key: 'done', value: 'Done' }
  ]

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    let data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value).map(result => result.item)
    } else {
      data = this.state.changelogs
    }

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value} />

        <div className={css.changelogForm}>
          <div className={css.row}>
            <input
              className={css.changelogFormField}
              type="text"
              placeholder="Please provide a title"
              onChange={e => this.setState({ title: e.target.value })}
              value={this.state.title}
              onKeyUp={this.onKeyUp}
            />
          </div>
          <div className={css.row}>
            <textarea
              className={css.changelogFormField}
              placeholder="Please provide a description"
              rows="4"
              onChange={e => this.setState({ description: e.target.value })}
              value={this.state.description}
              onKeyUp={this.onKeyUp}
            />
          </div>
          <div className={css.row}>
            <input
              className={css.changelogFormField}
              type="text"
              placeholder="What category?"
              onChange={e => this.setState({ category: e.target.value })}
              value={this.state.category}
              onKeyUp={this.onKeyUp}
            />
            <select
              value={this.state.status}
              className={css.changelogFormField}
              onChange={e => this.setState({ status: e.target.value })}
            >
              <option value="planned">Planned</option>
              <option value="inProgress">In Progress</option>
              <option value="done">Done</option>
            </select>
            <button className={css.addChangelogButton} onClick={this.handleAddChangelog}>
              Add Changelog
            </button>
          </div>
        </div>

        <div className={css.table}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                rowClassName={({ index }) => (index % 2 === 0 ? 'even' : null)}
              >
                <Column
                  className={css.rowField}
                  label="Category"
                  dataKey="category"
                  width={200}
                  cellRenderer={({ rowData }) =>
                    this.state.editableRowId === rowData.id &&
                    this.state.editableField === 'category' ? (
                      <div className={css.editableCell}>
                        <input
                          value={this.state.editableValue}
                          onKeyDown={this.onKeyDown}
                          onChange={this.onChange}
                          autoFocus
                        />
                        <div className={css.saveButton} onClick={this.handleUpdate}>
                          <CheckIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.cellContent}
                        onClick={() => this.onClick(rowData.id, 'category', rowData.category)}
                      >
                        {rowData.category}
                      </div>
                    )
                  }
                />

                <Column
                  className={css.rowField}
                  label="Title"
                  dataKey="title"
                  width={450}
                  cellRenderer={({ rowData }) =>
                    this.state.editableRowId === rowData.id &&
                    this.state.editableField === 'title' ? (
                      <div className={css.editableCell}>
                        <input
                          value={this.state.editableValue}
                          onChange={this.onChange}
                          onKeyDown={this.onKeyDown}
                          autoFocus
                        />
                        <div className={css.saveButton} onClick={this.handleUpdate}>
                          <CheckIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.cellContent}
                        onClick={() => this.onClick(rowData.id, 'title', rowData.title)}
                      >
                        {rowData.title}
                      </div>
                    )
                  }
                />

                <Column
                  className={css.rowField}
                  label="Description"
                  dataKey="description"
                  width={900}
                  cellRenderer={({ rowData }) =>
                    this.state.editableRowId === rowData.id &&
                    this.state.editableField === 'description' ? (
                      <div className={css.editableCell}>
                        <input
                          value={this.state.editableValue}
                          onKeyDown={this.onKeyDown}
                          onChange={this.onChange}
                          autoFocus
                        />
                        <div className={css.saveButton} onClick={this.handleUpdate}>
                          <CheckIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.cellContent}
                        onClick={() => this.onClick(rowData.id, 'description', rowData.description)}
                      >
                        {rowData.description}
                      </div>
                    )
                  }
                />

                <Column
                  className={css.rowField}
                  label="Status"
                  dataKey="status"
                  width={200}
                  cellRenderer={({ rowData }) =>
                    this.state.editableRowId === rowData.id &&
                    this.state.editableField === 'status' ? (
                      <div className={css.editableCell}>
                        <select value={this.state.editableValue} onChange={this.onChange}>
                          <option value="planned">Planned</option>
                          <option value="inProgress">In Progress</option>
                          <option value="done">Done</option>
                        </select>
                        <div className={css.saveButton} onClick={this.handleUpdate}>
                          <CheckIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.cellContent}
                        onClick={() => this.onClick(rowData.id, 'status', rowData.status)}
                      >
                        {this.statuses.find(item => item.key === rowData.status)?.value}
                      </div>
                    )
                  }
                />

                <Column
                  className={css.rowField}
                  label="Updated At"
                  dataKey="updatedAt"
                  width={200}
                  cellRenderer={({ rowData }) =>
                    this.state.editableRowId === rowData.id &&
                    this.state.editableField === 'updatedAt' ? (
                      <div className={css.editableCell}>
                        <DatePicker
                          selected={new Date(this.state.editableValue).getTime()}
                          onChange={date => this.onUpdatedAtChange(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                          withPortal
                          portalId="root"
                          maxDate={new Date().getTime()}
                        />
                        <div className={css.saveButton} onClick={this.handleUpdate}>
                          <CheckIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={css.cellContent}
                        onClick={() => this.onClick(rowData.id, 'updatedAt', rowData.updatedAt)}
                      >
                        {TimeAgo(rowData.updatedAt)}
                      </div>
                    )
                  }
                />

                <Column
                  className={css.rowField}
                  label="Created at"
                  dataKey="createdAt"
                  cellRenderer={({ cellData }) => TimeAgo(cellData)}
                  width={200}
                />

                <Column
                  className={css.rowField}
                  label="Delete"
                  dataKey="id"
                  cellRenderer={({ cellData }) => (
                    <button className={css.delete} onClick={() => this.handleDelete(cellData)}>
                      <TrashIcon />
                    </button>
                  )}
                  width={50}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default ProductUpdates
