import API from '../api'
import cache from '../cache'

//get user data from api

export default class Users {
  static GetList = cb => {
    const cacheKey = 'users'
    const cached = cache.Get(cacheKey)
    if (cached && cached.length) {
      cb(cached)
      return
    }

    API.Get({
      path: '/admin/users',
      success: res => {
        if (res && res.length) {
          cache.Set(cacheKey, res)
        }
        cb(res)
      },
      error: cb
    })
  }
}
