import API from '../api'

export default class ProductUpdate {
  static GetAll = cb => {
    API.Get({
      path: '/admin/product-updates',
      success: cb,
      error: cb
    })
  }

  static Create = (data, cb) => {
    API.Post({
      path: '/admin/product-update',
      data: data,
      success: cb,
      error: cb
    })
  }

  static Update = (id, data, cb) => {
    API.Patch({
      path: '/admin/product-update/' + id,
      data: data,
      success: cb,
      error: cb
    })
  }

  static Delete = (id, cb) => {
    API.Delete({
      path: '/admin/product-update/' + id,
      success: cb,
      error: cb
    })
  }
}
