const cdn = 'https://cdn.umso.com'
const imgix = 'https://landen.imgix.net'
const assetRoot = 'https://assets.umso.com'

const hostname = window.location.hostname

const isStaging = function () {
  return localStorage.isStaging === 'true'
}

const isLocal = function (host) {
  return host === 'admin.local.umso.com'
}

let API_ROOT

if (isStaging(hostname)) {
  API_ROOT = 'https://api-staging.umso.com'
} else if (isLocal(hostname)) {
  API_ROOT = 'https://api.local.umso.com:8000'
} else {
  API_ROOT = 'https://api.umso.com'
}

export default {
  API_ROOT: API_ROOT,
  CDN_ROOT: cdn,
  IMGIX_ROOT: imgix,
  ASSET_ROOT: assetRoot
}
