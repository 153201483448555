import API from './api'

// This class is for the currently logged-in user
class User {
  constructor () {
    this.user = {}
    this.enableDarkmode = false
  }

  IsLoggedIn () {
    return this.user.id && this.user.id !== 0
  }

  CheckAuth = (cb) => {
    API.Get({
      path: '/me',
      success: (data) => {
        if (data.id) {
          this.user = data
        }
        cb && cb(data)
      },
      error: () => {},
    })
  }

  Login = (email, password, cb) => {
    API.Post({
      path: '/auth/login',
      data: {
        email: email,
        password: password,
      },
      success: (res) => {
        // get user state from api, and call cb'
        if (res.error) {
          cb(res)
        } else {
          this.CheckAuth(() => cb(res))
        }
      },
      error: cb,
    })
  }
}

// export the user as instance so we always refer to this
// instance when importing it anywhere
export default new User()
