import API from '../api'
import cache from '../cache'

export default class Sites {
  static GetList = (pageNumber, cb) => {
    const cacheKey = 'sites-page-' + pageNumber
    const cached = cache.Get(cacheKey)

    if (cached && cached?.sites?.length) {
      cb(cached)
      return
    }

    API.Get({
      path: '/admin/sites' + (pageNumber ? '?page=' + pageNumber : ''),
      success: res => {
        if (res?.sites?.length) {
          cache.Set(cacheKey, res)
        }
        cb(res)
      },
      error: cb
    })
  }

  static GetBySearch = (query, cb) => {
    console.log('getBySearch', query)
    let queryParams = []

    if (!query) {
      console.error('No query provided')
      return
    }

    if (query.searchText != null) {
      queryParams.push('searchText=' + query.searchText)
    }

    if (query.isPublished != null) {
      queryParams.push('isPublished=' + query.isPublished)
    }

    if (query.engineVersion != null) {
      queryParams.push('engineVersion=' + query.engineVersion)
    }

    if (query.status != null) {
      queryParams.push('status=' + query.status)
    }

    const queryString = queryParams.join('&')

    API.Get({
      path: '/admin/sites/search?' + queryString,
      success: res => {
        cb(res)
      },
      error: cb
    })
  }

  static RepublishSite = (id, cb) => {
    if (window.confirm(`You really want to republish this site? ${id}`)) {
      API.Post({
        path: '/admin/sites/' + id + '/republish',
        success: cb,
        error: cb
      })
    } else {
      return null
    }
  }
}
