import React, { Component } from 'react'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  SortDirection,
  Table
} from 'react-virtualized'
import Fuse from 'fuse.js'
import _ from 'lodash'
import queryString from 'query-string'
import { format, parseISO } from 'date-fns'

import FeedbackData from '../../../data/models/feedbacks'
import Loader from '../../../layout/loader'
import Searchbar from '../../../layout/searchbar'

import css from '../../../../css/dashboard.module.css'

//options from fuse
const options = {
  threshold: 0.1,
  //keys that fuse filters in the search
  keys: ['userId']
}

//get correct cell height and width
const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 80
})

class Cancellation extends Component {
  constructor(props) {
    super(props)

    const sortBy = 'nothing'
    const sortDirection = SortDirection.ASC
    const sortedList = this._sortList({ sortBy, sortDirection })

    this.state = {
      sortBy,
      sortDirection,
      sortedList,
      sort: false,
      data: [],
      isLoading: true,
      fuse: null,
      isStaging: localStorage.isStaging === 'true',
      selectOption: 'all'
    }
  }

  componentDidMount() {
    FeedbackData.GetCancellation(data => {
      const params = queryString.parse(this.props.location.search)
      const fuse = new Fuse(data, options)
      this.setState({
        data: data,
        isLoading: false,
        selectOption: params.search || 'all',
        fuse: fuse
      })
    })
  }

  handleReasonSelect = e => {
    this.setState({ loading: true })

    window.location.assign('/cancellation?search=' + e.target.value)
  }

  _sortList = ({ data, sortBy, sortDirection }) => {
    let newList = _.sortBy(data, [sortBy])
    if (sortDirection === SortDirection.DESC) {
      newList.reverse()
    }
    return newList
  }

  _sort = ({ sortBy, sortDirection }) => {
    const sortedList = this._sortList({ sortBy, sortDirection })
    this.setState({ sortBy, sortDirection, sortedList })
  }

  resetSorting = () => {
    this.setState({ sortBy: 'nothing' })
  }

  sortDataBySelection = (data, value) => {
    return data.filter(data => data.response === value)
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    //reverse to show new feedback on top
    var data = [...this.state.data].reverse()

    //sorting
    this._sortList = ({ sortBy, sortDirection }) => {
      let newList = _.sortBy(data, [sortBy])
      if (sortDirection === SortDirection.DESC) {
        newList.reverse()
      }
      return newList
    }

    this._sort = ({ sortBy, sortDirection }) => {
      const sortedList = this._sortList({ sortBy, sortDirection })
      this.setState({ sortBy, sortDirection, sortedList })
    }

    if (this.state.sortBy !== 'nothing') {
      data = this.state.sortedList
    }

    // // filter for selection
    if (this.state.selectOption !== 'all') {
      data = this.sortDataBySelection(data, this.state.selectOption)
    }

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value} noSearch>
          <div className={css.filterCheckbox}>
            <label htmlFor="select">Reason</label>
            <select
              value={this.state.selectOption}
              className={css.selectFilter}
              onChange={this.handleReasonSelect}
            >
              <option>all</option>
              <option>needs-not-met</option>
              <option>missing-features</option>
              <option>bad-reliability</option>
              <option>other</option>
              <option>no-longer-needed</option>
              <option>bad-service</option>
              <option>too-many-bugs</option>
            </select>
          </div>
        </Searchbar>
        <div className={css.table}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                sort={this._sort}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={cache.rowHeight}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  disableSort
                  label="Reason"
                  dataKey="response"
                  cellRenderer={({ cellData }) => {
                    const formattedText = cellData?.replaceAll('-', ' ')
                    return <p>{formattedText}</p>
                  }}
                  width={250}
                  height={80}
                />
                <Column
                  disableSort
                  label="Comment and all reasons in ()"
                  dataKey="comment"
                  width={500}
                  cellRenderer={({ columnIndex, key, parent, rowIndex, style, cellData }) => {
                    const formattedText = cellData?.replaceAll('-', ' ')
                    return (
                      <CellMeasurer
                        cache={cache}
                        columnIndex={0}
                        key={key}
                        parent={parent}
                        rowIndex={rowIndex}
                      >
                        <div className={css.feedback}>{formattedText}</div>
                      </CellMeasurer>
                    )
                  }}
                />
                <Column
                  disableSort
                  label="userId"
                  dataKey="userId"
                  cellRenderer={({ cellData }) => (
                    <a href={'/users?search=' + cellData}>{cellData}</a>
                  )}
                  width={100}
                  height={80}
                />
                <Column
                  disableSort={this.state.selectOption !== 'all'}
                  label="created at"
                  dataKey="createdAt"
                  cellRenderer={({ cellData }) => format(parseISO(cellData), 'yyyy-MM-dd HH:mm')}
                  width={250}
                  height={80}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default Cancellation
