// API abstracts away some networking
// to reach our backend API
import 'whatwg-fetch'
import Axios from 'axios'

import config from './config'

export default class API {
  // performs a GET request
  static Get ({ path, success, error }) {
    let status
    fetch(config.API_ROOT + path, {
      credentials: 'include',
      headers: {
        'x-landen-org': 'admin',
      },
    })
      .then(function (response) {
        status = response.status
        return response.json()
      })
      .then(function (json) {
        success(json, status)
      })
      .catch(function (ex) {
        error && error(null, status)
      })
  }

  // performas a POST request
  static Post ({ path, data, success, error }) {
    fetch(config.API_ROOT + path, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'x-landen-org': 'admin',
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        success(json)
      })
      .catch(function (ex) {
        error && error(ex)
      })
  }

  static PostProgress ({ path, data, success, error, onProgress }) {
    Axios.post(config.API_ROOT + path, data, {
      withCredentials: true,
      headers: {
        'x-landen-org': 'admin',
      },
      onUploadProgress: function (e) {
        onProgress && onProgress(Math.floor((e.loaded / e.total) * 100))
      },
    })
      .then(function (response) {
        success(response.data)
      })
      .catch(function (err) {
        // handle error
        if (err) {
          console.error(err)
          error && error(err)
        }
      })
  }

  // performas a POST request
  static Patch ({ path, data, success, error }) {
    fetch(config.API_ROOT + path, {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'x-landen-org': 'admin',
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        success(json)
      })
      .catch(function (ex) {
        error && error(ex)
      })
  }

  // performas a POST request
  static Delete ({ path, data, success, error }) {
    fetch(config.API_ROOT + path, {
      method: 'DELETE',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: {
        'x-landen-org': 'admin',
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (json) {
        success(json)
      })
      .catch(function (err) {
        error && error(err)
      })
  }
}
