import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./buttons.css";

class Button extends Component {
  getBadge = (badge) => {
    if (!badge) {
      return null;
    }

    return <span className="badge">{badge}</span>;
  };

  render() {
    let classes = classNames({
      button: true,
      "button--primary": this.props.primary,
      "button--secondary": this.props.secondary,
      "button--loading": this.props.loading,
      "button--disabled": this.props.disabled,
      "button--danger": this.props.danger,
      "button--large": this.props.large,
      "button--small": this.props.small,
      "button--ellipsis": this.props.ellipsis,
      "button--light": this.props.light,
      "button--clear": this.props.clear,
      "button--play": this.props.play && !this.props.loading,
    });

    if (this.props.className) {
      classes += " " + this.props.className;
    }

    if (this.props.href) {
      return (
        <a
          href={this.props.href}
          target="_blank"
          rel="noopener noreferrer"
          className={classes}
          disabled={this.props.disabled}
          style={this.props.style}
          data-tooltip={this.props.tooltip}
        >
          {this.props.before}
          {this.props.title || this.props.children}
          {this.getBadge(this.props.badge)}
          {!this.props.loading && this.props.after}
        </a>
      );
    }

    if (this.props.to) {
      return (
        <Link
          to={this.props.to}
          className={classes}
          disabled={this.props.disabled}
          style={this.props.style}
          data-tooltip={this.props.tooltip}
        >
          {this.props.before}
          {this.props.title || this.props.children}
          {this.getBadge(this.props.badge)}
          {!this.props.loading && this.props.after}
        </Link>
      );
    }

    return (
      <button
        className={classes}
        onClick={
          this.props.loading || this.props.disabled
            ? () => {}
            : this.props.onClick
        }
        disabled={this.props.disabled}
        style={this.props.style}
        data-tooltip={this.props.tooltip}
      >
        {this.props.before}
        {this.props.title || this.props.children}
        {this.getBadge(this.props.badge)}
        {!this.props.loading && this.props.after}
      </button>
    );
  }
}

export default Button;
