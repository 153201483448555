import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import Sites from '../pages/sites'
import Users from '../pages/users'
import Orgs from '../pages/orgs'
import Blogs from '../pages/blogs'
import Forms from '../pages/forms'
import Lists from '../pages/lists'
import OrgsId from '../pages/orgs/orgsId'
import Home from '../pages/home/home'
import Moderation from '../pages/moderation'
import Keywords from '../pages/keywords'
import Libraries from '../pages/libraries'
import Message from '../pages/feedback/message'
import Cancellation from '../pages/feedback/cancellation'
import ProductUpdates from '../pages/product-updates'
import PagesScreenshots from '../pages/screenshots/pages'

class Routes extends Component {
  render() {
    return (
      <div>
        {/* switch between the different pages: users, sites and orgs */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/sites" component={Sites} />
          <Route path="/pages-screenshots" component={PagesScreenshots} />
          <Route path="/users" component={Users} />
          <Route path="/orgs" exact component={Orgs} />
          <Route path="/orgs/:id" component={OrgsId} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/forms" component={Forms} />
          <Route path="/lists" component={Lists} />
          <Route path="/product-updates" component={ProductUpdates} />
          <Route path="/moderation" component={Moderation} />
          <Route path="/keywords" component={Keywords} />
          <Route path="/libraries" component={Libraries} />
          <Route path="/message" component={Message} />
          <Route path="/cancellation" component={Cancellation} />
        </Switch>
      </div>
    )
  }
}

export default Routes
