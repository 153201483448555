class Cache {
  constructor() {
    this.store = {}
  }

  Set(key, value) {
    this.store[key] = value
  }

  Get(key) {
    return this.store[key]
  }

  Delete(key) {
    delete this.store[key]
  }
}

export default new Cache()
