import API from '../api'

//get orgId data from api

export default class Org {
  static GetOrgsDetail = (id, cb) => {
    API.Get({
      path: '/admin/orgs/' + id,
      success: cb,
      error: cb
    })
  }

  static UpdatePolicy = (id, data, cb) => {
    API.Patch({
      path: '/admin/orgs/' + id + '/policy',
      data: data,
      success: cb,
      error: cb
    })
  }

  static BanOrg = (id, cb) => {
    if (window.confirm(`You really want to ban this Org? ${id}`)) {
      API.Post({
        path: '/admin/orgs/' + id + '/ban',
        success: cb,
        error: cb
      })
    } else {
      return null
    }
  }

  static UnbanOrg = (id, cb) => {
    if (window.confirm(`You really want to unban this Org? ${id}`)) {
      API.Post({
        path: '/admin/orgs/' + id + '/unban',
        success: cb,
        error: cb
      })
    } else {
      return null
    }
  }

  static RepublishAllOrgSites = (id, cb) => {
    if (window.confirm(`You really want to republish all sites from this Org? ${id}`)) {
      API.Post({
        path: '/admin/orgs/' + id + '/sites/republish',
        success: cb,
        error: cb
      })
    } else {
      return null
    }
  }

  static MakeOwner = (id, mid, cb) => {
    if (window.confirm(`You really want to make ${mid} owner of ${id}?`)) {
      API.Patch({
        path: '/organizations/' + id + '/members/' + mid + '/ownership',
        success: cb,
        error: cb
      })
    } else {
      return null
    }
  }
}
