import API from '../api'
import cache from '../cache'

//get org data from api

export default class Lists {
  static GetList = cb => {
    const cacheKey = 'lists'
    const cached = cache.Get(cacheKey)
    if (cached && cached.length) {
      cb(cached)
      return
    }
    API.Get({
      path: '/admin/lists',
      success: res => {
        if (res && res.length) {
          cache.Set(cacheKey, res)
        }
        cb(res)
      },
      error: cb
    })
  }
}
