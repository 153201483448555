import React from 'react'

import numeral from 'numeral'

import Search from '../../images/search'

import css from './searchbar.module.css'

function Searchbar(props) {
  const textInput = e => {
    props.onSearch(e.target.value)
  }

  if (props.noSearch) {
    return (
      <div className={css.searchbar}>
        {props.children}
        <div className={css.resultCounter}>Results: {numeral(props.length).format()}</div>
      </div>
    )
  }

  return (
    <div className={css.searchbar}>
      <Search />
      <input
        size="40"
        type="text"
        value={props.value}
        onChange={textInput}
        className={css.inputSearchbar}
        placeholder="Search..."
      />
      <div className={css.resultCounter}>Results: {numeral(props.length).format()}</div>
      {props.children}
      <button onClick={() => window.location.reload()} className={css.loadButton}>
        Reload Data
      </button>
    </div>
  )
}

export default Searchbar
