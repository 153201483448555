import Toastify from 'toastify-js'

export const banToast = () => {
  Toastify({
    text: 'Org Banned!',
    offset: {
      x: 50,
      y: 30
    },
    position: 'right',
    gravity: 'bottom',
    backgroundColor: 'red'
  }).showToast()
}

export const republishToast = () => {
  Toastify({
    text: 'Republished!',
    offset: {
      x: 50,
      y: 30
    },
    position: 'right',
    gravity: 'bottom',
    backgroundColor: 'rgb(57, 118, 255)'
  }).showToast()
}
