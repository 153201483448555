import API from '../api'

export default class FeedbackData {
  static GetMessage = cb => {
    API.Get({
      path: '/admin/feedbacks/feedback',
      success: cb,
      error: cb
    })
  }

  static GetCancellation = cb => {
    API.Get({
      path: '/admin/feedbacks/cancellation',
      success: cb,
      error: cb
    })
  }
}
