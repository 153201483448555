import React, { Component } from 'react'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import { AutoSizer, Column, Table } from 'react-virtualized'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import Searchbar from '../../layout/searchbar'
import Orgs from '../../data/models/orgs'
import Loader from '../../layout/loader'

import done from '../../../images/done-24px.svg'
import clear from '../../../images/clear-24px.svg'

import css from '../../../css/dashboard.module.css'

//options from fuse
const options = {
  threshold: 0.1,
  //keys that fuse filters in the search
  keys: ['name', 'members.email', 'policy', 'id']
}

class OrgsList extends Component {
  state = {
    orgs: [],
    isLoading: true,
    fuse: null,
    checked: false,
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    //get data from API
    Orgs.GetList(data => {
      const params = queryString.parse(this.props.location.search)
      // initialize fuse because we need the data from orgs
      const fuse = new Fuse(data, options)
      this.setState({
        orgs: data,
        isLoading: false,
        value: params.search || '',
        fuse: fuse
      })
    })
  }

  handleFilter = () => {
    if (this.state.checked === false) {
      this.setState({ checked: true })
    } else {
      this.setState({ checked: false })
    }
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    //"create new data" from the fuse search
    let data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(org => org.item)
    } else {
      data = this.state.orgs
    }

    if (this.state.checked === true) {
      let filteredOrgs = data
      filteredOrgs = filteredOrgs.map(element => (element.policy.trialEnd ? element : 'noTrial'))

      function checkIfTrial(item) {
        return item !== 'noTrial'
      }

      data = filteredOrgs.filter(checkIfTrial)
    }

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value}>
          <div className={css.filterCheckbox}>
            <input type="checkbox" onClick={this.handleFilter} id="checkbox" />
            <label htmlFor="checkbox">on Trial</label>
          </div>
        </Searchbar>

        <div className={css.table}>
          {/* get maximal height and width */}
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                // define index even for every secon row - "zebralook"
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="trial end"
                  dataKey="name"
                  width={200}
                  cellRenderer={({ rowData }) =>
                    rowData.policy.trialEnd
                      ? format(parseISO(rowData.policy.trialEnd), 'yyyy-MM-dd HH:mm')
                      : null
                  }
                />
                <Column
                  label="subscription"
                  dataKey="policy"
                  cellRenderer={({ cellData }) => {
                    if (cellData.siteLimit === undefined) {
                      return 'Free Legacy'
                    } else if (cellData.siteLimit === 1) {
                      return 'Basic'
                    } else if (cellData.siteLimit === 5) {
                      return 'Business'
                    } else if (cellData.siteLimit === 3) {
                      return 'Startup'
                    } else if (cellData.siteLimit === 15) {
                      return 'Growth Legacy'
                    } else if (cellData.siteLimit === 75) {
                      return 'Business Legacy'
                    } else {
                      return 'NA'
                    }
                  }}
                  width={150}
                />
                <Column
                  label="Org name/id"
                  defaultSortDirection={'ascending'}
                  dataKey="name"
                  width={250}
                  cellRenderer={({ rowData }) => (
                    <a href={'/orgs/' + rowData.id} target="_blank" rel="noopener noreferrer">
                      {rowData.name || rowData.id}
                    </a>
                  )}
                />
                <Column
                  label="Members"
                  dataKey="members"
                  //create new array with members id and then show the mail
                  cellRenderer={({ cellData }) =>
                    cellData.map(members => (
                      <Link to={'/users?search=' + members.email}>{members.email || 'na'}</Link>
                    ))
                  }
                  width={250}
                />
                <Column
                  label="Stripe-Id"
                  dataKey="stripeCustId"
                  width={120}
                  cellRenderer={({ cellData }) =>
                    cellData ? (
                      // a for external links with href
                      <a
                        href={'https://dashboard.stripe.com/customers/' + cellData}
                        //new tab
                        target="_blank"
                        //react wants it to be safe
                        rel="noopener noreferrer"
                      >
                        Stripe
                      </a>
                    ) : null
                  }
                />
                <Column
                  label="siteLimit"
                  dataKey="policy"
                  cellRenderer={({ cellData }) => cellData.siteLimit || '1'}
                  width={100}
                />
                <Column
                  label="hasExport"
                  dataKey="policy"
                  cellRenderer={({ cellData }) =>
                    cellData.hasExport ? (
                      //if cellData is available show done image
                      <img src={done} alt="done" />
                    ) : (
                      //else show clear image
                      <img src={clear} alt="clear" />
                    )
                  }
                  width={100}
                />
                <Column
                  label="userlimit"
                  dataKey="policy"
                  cellRenderer={({ cellData }) => cellData.teamMemberLimit || '1'}
                  width={100}
                />
                <Column
                  label="Creation Date"
                  dataKey="createdAt"
                  cellRenderer={({ cellData }) =>
                    //format the date to relative date
                    formatDistanceToNowStrict(parseISO(cellData))
                  }
                  width={150}
                />
                <Column
                  dataKey="createdAt"
                  cellRenderer={({ cellData }) =>
                    //format the date to absolute date
                    format(parseISO(cellData), 'yyyy-MM-dd HH:mm')
                  }
                  width={150}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default OrgsList
