import React, { Component } from 'react'
import Switch from 'react-switch'
import { Link } from 'react-router-dom'

import css from './sidebar.module.css'
import Users from '../../images/account_box-24px.js'
import Orgs from '../../images/work-24px.js'
import Sites from '../../images/language-24px.js'
import Screenshots from '../../images/screenshots.js'
import Blogs from '../../images/create-24px.js'
import Forms from '../../images/move_to_inbox-24px.js'
import Lists from '../../images/list_alt-24px.js'
import ProductUpdates from '../../images/product-updates.js'
import Moderation from '../../images/moderation.js'
import Keywords from '../../images/keywords'
import Chat from '../../images/chat'
import Cancel from '../../images/cancel'

const content = [
  {
    title: 'Sites',
    path: '/sites',
    img: Sites
  },
  {
    title: 'Screenshots',
    path: '/pages-screenshots',
    img: Screenshots
  },
  {
    title: 'Users',
    path: '/users',
    img: Users
  },
  {
    title: 'Orgs',
    path: '/orgs',
    img: Orgs
  },
  {
    title: 'Blogs',
    path: '/blogs',
    img: Blogs
  },
  {
    title: 'Forms',
    path: '/forms',
    img: Forms
  },
  {
    title: 'Lists',
    path: '/lists',
    img: Lists
  },
  {
    title: 'Updates',
    path: '/product-updates',
    img: ProductUpdates
  }
]

const contentFeedback = [
  {
    title: 'Message',
    path: '/message',
    img: Chat
  },
  {
    title: 'Cancellation',
    path: '/cancellation',
    img: Cancel
  }
]

const contentModeration = [
  {
    title: 'Flagged',
    path: '/moderation',
    img: Moderation
  },
  {
    title: 'Keywords',
    path: '/keywords',
    img: Keywords
  }
]

class Sidebar extends Component {
  state = { isStaging: localStorage.getItem('isStaging') === 'true' }

  handleEnvChange = () => {
    const isStaging = !this.state.isStaging
    this.setState({ isStaging })
    localStorage.isStaging = isStaging
    window.location.reload()
  }

  render() {
    return (
      <div className={css.sidebar}>
        {!this.state.isStaging ? (
          <Link className={css.headerProd} to="/">
            <span className={css.title}>Admin</span>
            <span className={css.subtitle}>Production</span>
          </Link>
        ) : (
          <Link className={css.headerStaging} to="/">
            <span className={css.title}>Admin</span>
            <span className={css.subtitle}>Staging</span>
          </Link>
        )}

        <div className={css.switch}>
          Staging
          <Switch
            onChange={this.handleEnvChange}
            checked={this.state.isStaging}
            offColor="#bbb"
            onColor="#186aff"
            offHandleColor="#fff"
            onHandleColor="#fff"
            uncheckedIcon={null}
            checkedIcon={null}
            boxShadow={null}
            handleDiameter={12}
            height={16}
            width={30}
          />
        </div>
        <ul>
          <div className={css.divider}>
            <span>Database</span>
          </div>
          {content.map((group, index) => {
            return (
              <li
                key={index}
                className={
                  this.props.location.pathname.includes(group.path) === true
                    ? css.active
                    : css.inactive
                }
              >
                <Link to={group.path} className={css.sidebarItem}>
                  <div className={css.img}>{group.img()}</div>
                  <span className={css.title}>{group.title}</span>
                </Link>
              </li>
            )
          })}
          <div className={css.divider}>
            <span>Feedback</span>
          </div>
          {contentFeedback.map((group, index) => {
            return (
              <li
                key={index}
                className={
                  this.props.location.pathname.includes(group.path) === true
                    ? css.activeSecondary
                    : css.inactive
                }
              >
                <Link to={group.path} className={css.sidebarItem}>
                  <div className={css.img}>{group.img()}</div>
                  <span className={css.title}>{group.title}</span>
                </Link>
              </li>
            )
          })}
          <div className={css.divider}>
            <span>Moderation</span>
          </div>
          {contentModeration.map((group, index) => {
            return (
              <li
                key={index}
                className={
                  this.props.location.pathname.includes(group.path) === true
                    ? css.activeSecondary
                    : css.inactive
                }
              >
                <Link to={group.path} className={css.sidebarItem}>
                  <div className={css.img}>{group.img()}</div>
                  <span className={css.title}>{group.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default Sidebar
