import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import numeral from 'numeral'

import Users from '../../../images/account_box-24px.js'
import Orgs from '../../../images/work-24px.js'
import Sites from '../../../images/language-24px.js'
import Blogs from '../../../images/create-24px.js'
import Forms from '../../../images/move_to_inbox-24px.js'
import Lists from '../../../images/list_alt-24px.js'
import HomeData from '../../data/models/home'

import css from './home.module.css'

const content = [
  {
    title: 'Users',
    title2: 'Verified',
    path: '/users',
    img: Users,
    desc: 'User Accounts on Umso',
    countKey: 'users',
    countKey2: 'usersVerified'
  },
  {
    title: 'Orgs',
    path: '/orgs',
    img: Orgs,
    desc: 'Created organisation with multiple users',
    countKey: 'orgs'
  },
  {
    title: 'Sites',
    title2: 'Published',
    path: '/sites',
    img: Sites,
    desc: 'Websites from Umso',
    countKey: 'sites',
    countKey2: 'sitesPublished'
  },
  {
    title: 'Blogs',
    title2: 'Posts',
    path: '/blogs',
    img: Blogs,
    desc: 'Blogs built with Umso',
    countKey: 'blogs',
    countKey2: 'blogposts'
  },
  {
    title: 'Forms',
    path: '/forms',
    img: Forms,
    desc: 'Custom forms',
    countKey: 'forms'
  },
  {
    title: 'Lists',
    path: '/lists',
    img: Lists,
    desc: 'Lists of mail addresses',
    countKey: 'emaillists'
  }
]

class Home extends Component {
  state = {
    counts: {},
    isLoading: true,
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    //get data from API
    HomeData.GetCounts(data => {
      //make the search work
      this.setState({
        counts: data,
        isLoading: false
      })
    })
  }

  render() {
    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div className={css.home} style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}>
        {content.map((group, index) => {
          return (
            <Link className={css.tile} key={index} to={group.path}>
              <div className={css.titleLink}>
                <div className={css.img}>{group.img()}</div>
                <span className={css.title}>{group.title}</span>
              </div>
              <div className={css.data}>
                <div className={css.wrapper}>
                  <span>{group.title} </span>
                  <p className={css.count}>{numeral(this.state.counts[group.countKey]).format()}</p>
                </div>

                {group.countKey2 !== undefined ? (
                  <div className={css.wrapper}>
                    <span> {group.title2} </span>{' '}
                    <p className={css.count}>
                      {numeral(this.state.counts[group.countKey2]).format()}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className={css.desc}>{group.desc}</div>
            </Link>
          )
        })}
      </div>
    )
  }
}

export default Home
