import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import queryString from 'query-string'

import Sidebar from '../layout/sidebar'
import Login from '../pages/login'
import Routes from './routes'
import user from '../data/user'
import '../../css/dashboard.css'

import Loader from '../layout/loader'

class AppRouter extends Component {
  state = { isLoggedIn: false, isLoading: true, isStaging: localStorage.isStaging === 'true' }

  componentDidMount() {
    //first check if logged in
    this.checkLogin()

    const query = queryString.parse(document.location?.search)
    if (query?.env === 'staging') {
      this.setState({ isStaging: true })
      localStorage.isStaging = true
      window.location = window.location.pathname
    }

    if (query?.env === 'prod') {
      this.setState({ isStaging: false })
      localStorage.isStaging = false
      window.location = window.location.pathname
    }
  }

  checkLogin = () => {
    //check if user is authorized
    user.CheckAuth(res => {
      const isLoggedIn = res.id !== undefined

      // redirect back to production view if staging isn't logged in
      if (this.state.isStaging && !isLoggedIn) {
        localStorage.isStaging = false
        this.setState({
          isLoading: true
        })
        window.open('https://app-staging.umso.com')
        window.location.reload()
      } else {
        this.setState({
          isLoading: false,
          isLoggedIn: isLoggedIn
        })
      }
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }
    if (!this.state.isLoggedIn) {
      return <Login />
    }
    return (
      //router to create a structure of linking in admin
      <Router>
        <>
          <Route component={Sidebar} />
          <Routes isStaging={this.state.isStaging} />
        </>
      </Router>
    )
  }
}

export default AppRouter
