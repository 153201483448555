import React, { Component } from 'react'
import { format, parseISO } from 'date-fns'
import { Link } from 'react-router-dom'
import Toastify from 'toastify-js'
import TextareaAutosize from 'react-textarea-autosize'

import 'toastify-js/src/toastify.css'
import 'react-virtualized/styles.css'

import Org from '../../../data/models/org'
import Loader from '../../../layout/loader'

import done from '../../../../images/done-24px.svg'
import clear from '../../../../images/clear-24px.svg'

import css from '../orgsId/orgsDetail.module.css'

class OrgsDetail extends Component {
  state = {
    id: this.props.match.params.id,
    orgsDetail: [],
    isLoading: true,
    policy: {},
    stripeId: '',
    subscription: {},
    isFetchingSave: false,
    isFetchingBan: false,
    isStaging: localStorage.isStaging === 'true',
    noteValue: ''
  }

  componentDidMount() {
    Org.GetOrgsDetail(this.state.id, data => {
      const initialNotes = data.org.policy.notes || ''
      this.setState({
        orgsDetail: data?.org,
        isLoading: false,
        policy: data?.org?.policy ? data.org.policy : [],
        subscription: data?.subscriptions[0],
        stripeId: data?.stripeCustId,
        noteValue: initialNotes
      })
    })
  }

  changeHandler = index => {
    let policy = { ...this.state.policy }
    const policyKeys = Object.keys(policy)
    const policyKey = policyKeys[index]
    const val = policy[policyKey]

    if (val === true) {
      policy[policyKey] = false
      this.setState({ policy: policy })
    } else if (val === false) {
      policy[policyKey] = true
      this.setState({ policy: policy })
    }
  }

  changeHandlerIntegration = (int, index) => {
    let policy = { ...this.state.policy }
    let integrations = [...this.state.policy.integrations]
    const hasIntegration = integrations.includes(int)

    if (hasIntegration) {
      const newIntegrations = integrations
        .map(integration => {
          if (integration !== int) {
            return integration
          } else return undefined
        })
        .filter(el => el !== undefined)

      integrations = newIntegrations
    } else {
      integrations = [...integrations, int]
    }

    policy.integrations = integrations

    this.setState({ policy: policy })
  }

  textInput = (index, e) => {
    let policy = { ...this.state.policy }
    const policyKeys = Object.keys(policy)
    const policyKey = policyKeys[index]

    if (typeof e.target.value === 'string') {
      policy[policyKey] = Number(e.target.value)
      this.setState({ policy: policy })
    }
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  updateToast = () => {
    Toastify({
      text: 'Updated',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'rgb(57, 118, 255)'
    }).showToast()
  }

  timeoutLoadingSafe = () => {
    setTimeout(() => this.setState({ isFetchingSave: false }), 1000)
    setTimeout(() => this.updateToast(), 1000)
  }

  timeoutLoadingBan = () => {
    setTimeout(() => this.setState({ isFetchingBan: false }), 1000)
    setTimeout(() => this.updateToast(), 1000)
    setTimeout(
      () =>
        Org.GetOrgsDetail(this.state.id, data => {
          this.setState({
            orgsDetail: data,
            policy: data.policy
          })
        }),
      1000
    )
  }

  makeOwner = (id, mid) => {
    Org.MakeOwner(id, mid, () => window.location.reload())
  }

  changelHandlerNotes = e => {
    let policy = { ...this.state.policy }
    policy.notes = e.target.value

    this.setState({ noteValue: e.target.value, policy: policy })
  }

  render() {
    const { isLoading, orgsDetail, policy, stripeId, subscription } = this.state
    if (isLoading) {
      return <Loader />
    }

    const policyKeys = Object.keys(orgsDetail.policy)
    const metaTitle = Object.keys(orgsDetail.meta)
    const isBanned = policy?.isBanned ? policy.isBanned : false

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.tablePolicyWrapper}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <div className={css.policyHeaderWrapper}>
          <h2>Policy</h2>
          <div className={css.tableWrapperBorder}>
            <table>
              <tr>
                <th className={css.tableTitle}>Property</th>
                <th className={css.tableTitle}>Value</th>
              </tr>
              <tr className={css.banCustomer}>
                <td className={css.banRow}>Ban Customer</td>
                <td className={css.banRow}>
                  {isBanned ? (
                    <div className={css.buttonWrapper}>
                      <button
                        className={css.unbanButton}
                        disabled={this.state.isFetching}
                        onClick={() => {
                          this.setState({ isFetchingBan: true })
                          Org.UnbanOrg(this.state.id)
                          this.timeoutLoadingBan()
                        }}
                      >
                        {this.state.isFetchingBan ? 'loading' : 'Unban'}
                      </button>
                    </div>
                  ) : (
                    <div className={css.buttonWrapper}>
                      <button
                        className={css.banButton}
                        disabled={this.state.isFetching}
                        onClick={() => {
                          this.setState({ isFetchingBan: true })
                          Org.BanOrg(this.state.id)
                          this.timeoutLoadingBan()
                        }}
                      >
                        {this.state.isFetchingBan ? 'loading' : 'Ban'}
                      </button>
                    </div>
                  )}
                </td>
              </tr>
              {policyKeys.map((key, index) => {
                if (key === 'notes') {
                  return (
                    <tr className={css.tableRow}>
                      <td>{key}</td>
                      <td className={css.tableElement}>
                        <TextareaAutosize
                          value={this.state.noteValue}
                          onChange={e => this.changelHandlerNotes(e)}
                          minRows={3}
                        />
                      </td>
                    </tr>
                  )
                }

                const val = policy[key]
                return (
                  <tr className={css.tableRow}>
                    <td>{key}</td>
                    <td className={css.tableElement}>
                      {typeof val === 'boolean' ? (
                        <label className={css.checkboxLabel}>
                          <input
                            type="checkbox"
                            checked={val}
                            onClick={() => this.changeHandler(index)}
                          />
                        </label>
                      ) : typeof val === 'number' ? (
                        <label className={css.inputIntegersLabel}>
                          <input
                            type="number"
                            className={css.inputIntegers}
                            value={val}
                            onChange={e => this.textInput(index, e)}
                          />
                        </label>
                      ) : typeof val === 'string' ? (
                        <label className={css.inputStringLabel}>
                          <p>{val}</p>
                        </label>
                      ) : Array.isArray(val) === true ? (
                        <label className={css.inputArrayLabel}>
                          <label className={css.integrationCheckbox}>
                            <p>mailchimp</p>
                            <input
                              type="checkbox"
                              checked={this.state.policy.integrations.includes('mailchimp')}
                              onClick={() => this.changeHandlerIntegration('mailchimp', index)}
                            />
                          </label>
                          <label className={css.integrationCheckbox}>
                            <p>stripe</p>
                            <input
                              type="checkbox"
                              checked={this.state.policy.integrations.includes('stripe')}
                              onClick={() => this.changeHandlerIntegration('stripe', index)}
                            />
                          </label>
                          <label className={css.integrationCheckbox}>
                            <p>zapier</p>
                            <input
                              type="checkbox"
                              checked={this.state.policy.integrations.includes('zapier')}
                              onClick={() => this.changeHandlerIntegration('zapier', index)}
                            />
                          </label>
                        </label>
                      ) : (
                        val
                      )}
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td className={css.safeRow}>Save Changes</td>
                <td className={css.safeRow}>
                  <div className={css.buttonWrapper}>
                    <button
                      disabled={this.state.isFetching}
                      onClick={() => {
                        this.setState({ isFetchingSave: true })
                        Org.UpdatePolicy(this.state.id, this.state.policy)
                        this.timeoutLoadingSafe()
                      }}
                    >
                      {this.state.isFetchingSave ? 'loading' : 'Save'}
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className={css.subcriptionMetaWrapper}>
          <div className={css.tableMetaWrapper}>
            <h2>Meta</h2>
            <div className={css.tableWrapperBorder}>
              <table>
                <tr>
                  <th className={css.tableTitle}>Property</th>
                  <th className={css.tableTitle}>Value</th>
                </tr>
                {metaTitle.map(title => (
                  <tr className={css.tableRow}>
                    <td>{title}</td>
                    <td className={css.tableElement}>
                      {orgsDetail.meta[title] ? (
                        orgsDetail.meta[title].toString()
                      ) : (
                        <img src={clear} alt="clear" />
                      )}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          <div className={css.tableMetaWrapper}>
            <h2>Subscription</h2>
            <div className={css.tableWrapperBorder}>
              {subscription ? (
                <table>
                  <tr>
                    <th className={css.tableTitle}>Property</th>
                    <th className={css.tableTitle}>Value</th>
                  </tr>
                  <tr>
                    <td>Customer Id</td>
                    <td>
                      <a
                        href={'https://dashboard.stripe.com/customers/' + stripeId}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {stripeId}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Sub Id</td>
                    <td>
                      <a
                        href={'https://dashboard.stripe.com/subscriptions/' + subscription?.id}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {subscription?.id}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>product</td>
                    <td>
                      <ul>
                        {subscription?.items.map(sub => {
                          return (
                            <>
                              <li>{sub.quantity} x</li>
                              <li>{sub.name}</li>
                              <li>${sub.amount / 100}</li>
                            </>
                          )
                        })}
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>status</td>
                    <td>{subscription?.status}</td>
                  </tr>
                  <tr>
                    <td>cancelled</td>
                    <td>{subscription?.cancelAtPeriodEnd ? 'true' : 'false'}</td>
                  </tr>
                  {subscription?.discount && (
                    <tr>
                      <td>discount</td>
                      <td>
                        {subscription?.discount?.percentageOff +
                          '% until ' +
                          (subscription?.discount?.end === '1970-01-01T00:00:00Z'
                            ? 'forever'
                            : format(parseISO(subscription?.discount?.end), 'yyyy-MM-dd HH:mm'))}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>created at</td>
                    <td className={css.tableElement}>
                      {format(parseISO(subscription?.createdAt), 'yyyy-MM-dd HH:mm')}
                    </td>
                  </tr>
                  <tr>
                    <td>period end</td>
                    <td className={css.tableElement}>
                      {format(parseISO(subscription?.periodEnd), 'yyyy-MM-dd HH:mm')}
                    </td>
                  </tr>
                </table>
              ) : (
                <table>
                  <tr>
                    <th className={css.tableTitle}>Property</th>
                    <th className={css.tableTitle}>Value</th>
                  </tr>
                  <tr>
                    <td>Customer Id</td>
                    <td>
                      <a
                        href={'https://dashboard.stripe.com/customers/' + stripeId}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {stripeId}
                      </a>
                    </td>
                  </tr>
                </table>
              )}
            </div>
          </div>
          <div className={css.assumeOrgButton}>
            <button
              onClick={() =>
                window.open(
                  (this.state.isStaging
                    ? 'https://app-staging.umso.com/?assumeOrgId='
                    : 'https://app.umso.com/?assumeOrgId=') + this.state.id
                )
              }
            >
              View Umso Dashboard as this Org
            </button>
          </div>
        </div>
        <div className={css.userHeaderWrapper}>
          <h2>Users:</h2>
          <div className={css.userWrapper}>
            {this.state.orgsDetail.users.map(user => (
              <div className={css.tableUserWrapper}>
                <div className={css.tableWrapperBorder}>
                  <table>
                    <tr>
                      <th className={css.tableTitle}>
                        User {this.state.orgsDetail.users.indexOf(user) + 1}
                      </th>
                      <th className={css.tableTitle}>Value</th>
                    </tr>
                    <tr>
                      <td> Name</td>
                      <td className={css.tableElement}>
                        {user.name ? user.name : <img src={clear} alt="clear" />}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td className={css.tableElement}>
                        <Link to={'/users?search=' + user.email}>{user.email}</Link>
                      </td>
                    </tr>
                    <tr>
                      <td>isAdmin</td>
                      <td className={css.tableElement}>
                        {user.isAdmin === true ? (
                          <img src={done} alt="done" />
                        ) : (
                          <img src={clear} alt="clear" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>isOwner</td>
                      <td className={css.tableElement}>
                        {user.userId === orgsDetail.createdBy ? (
                          <img src={done} alt="done" />
                        ) : (
                          <div className={css.makeOwnerWrapper}>
                            <img src={clear} alt="clear" />
                            <button
                              onClick={() =>
                                Org.MakeOwner(user.orgId, user.userId, () =>
                                  window.location.reload()
                                )
                              }
                            >
                              Make Owner
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>OrgsId</td>
                      <td className={css.tableElement}>{user.orgId}</td>
                    </tr>
                    <tr>
                      <td>userId</td>
                      <td className={css.tableElement}>{user.userId}</td>
                    </tr>
                    <tr>
                      <td>Created at</td>
                      <td className={css.tableElement}>
                        {format(parseISO(user.createdAt), 'yyyy-MM-dd HH:mm')}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default OrgsDetail
