import React, {Component} from "react";

import User from "../../data/user";
import {ValidateEmail} from "../../data/validators";

import "./auth.css";

import Button from "../../shared/button";

class Login extends Component {
    state = {
        email: "",
        pw: "",
        loading: false,
        error: "",
    };

    componentDidMount() {
        User.CheckAuth((res) => {
            if (res.id) {
                window.location.reload();
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.validateData(this.state.email, this.state.pw)) {
            return;
        }

        this.setState({loading: true});

        User.Login(this.state.email, this.state.pw, (res) => {
            if (res.success) {
                window.location.reload();
            } else {
                this.setState({loading: false, error: res.error});
            }
        });
    };

    validateData(email, pw) {
        if (!ValidateEmail(email)) {
            this.setState({error: "Please enter a valid email address"});
            return false;
        }
        if (pw.length === 0) {
            this.setState({error: "Please enter your password"});
            return false;
        }
        return true;
    }

    render() {
        return (
            <div style={{width: "100%"}}>
                <div className="authWrapper">
                    <h1 className="authHeader">Login</h1>
                    <div className="authBox">
                        <form onSubmit={this.onSubmit} className="authForm">
                            <div className="authFormSection">
                                <label>Email</label>
                                <input
                                    type="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value})}
                                    placeholder="email@example.com"
                                    autoFocus
                                />
                            </div>
                            <div className="authFormSection">
                                <label>Password</label>

                                <input
                                    type="password"
                                    value={this.state.pw}
                                    onChange={(e) => this.setState({pw: e.target.value})}
                                />
                            </div>
                            <div className="authFormError">{this.state.error}</div>
                            <div className="authFormActions">
                                <Button primary right large loading={this.state.loading}>
                                    Login
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
