import React, { Component } from 'react'
import { format, parseISO } from 'date-fns'
import queryString from 'query-string'
import { AutoSizer, Column, Table } from 'react-virtualized'
import Fuse from 'fuse.js'
import { Link } from 'react-router-dom'

import Blogs from '../../data/models/blogs'
import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'

import css from '../../../css/dashboard.module.css'

//options from fuse
const options = {
  threshold: 0.1,
  //keys that fuse filters in the search
  keys: ['title', 'createdBy.email', 'orgName', 'orgId', 'id']
}

class BlogsList extends Component {
  state = {
    blogs: [],
    isLoading: true,
    fuse: null,
    linkToBlog: '',
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    //get data from API
    Blogs.GetList(data => {
      const params = queryString.parse(this.props.location.search)

      // initialize fuse because we need the data from blogs
      const fuse = new Fuse(data, options)
      this.setState({
        blogs: data,
        value: params.search || '',
        fuse: fuse,
        isLoading: false
      })
    })
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  // //generate link to blog with data of sites
  // getLinkToBlog = orgId => {
  //   const orgIdOfSite = [...this.state.sites].map(org => org.orgId)
  //   const indexOfSiteForLink = orgIdOfSite.indexOf(orgId)
  //   const siteWithLink = this.state.sites[indexOfSiteForLink]
  //   var linkToBlog = ''
  //
  //   if (siteWithLink.domain !== null) {
  //     linkToBlog = siteWithLink.domain
  //   } else {
  //     linkToBlog = siteWithLink.subdomain + siteWithLink.subdomainSuffix
  //   }
  //
  //   return this.setState({ linkToBlog: linkToBlog })
  // }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    //"create new data" from the fuse search
    var data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(blogs => blogs.item)
    } else {
      data = this.state.blogs
    }

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value} />
        <div className={css.table}>
          {/* get maximal height and width */}
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                // define index even for every secon row - "zebralook"
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="Edit"
                  dataKey="id"
                  width={50}
                  cellRenderer={({ rowData }) => (
                    <a
                      href={
                        'https://app.umso.com/?assumeOrgId=' +
                        rowData.orgId +
                        '&path=blogs/' +
                        rowData.id +
                        '/edit'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Edit
                    </a>
                  )}
                />
                <Column
                  label="Blog"
                  dataKey="title"
                  width={250}
                  cellRenderer={({ rowData }) => <span>{rowData.title}</span>}
                />
                <Column
                  label="Id"
                  dataKey="id"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={300}
                />

                <Column
                  label="Creator"
                  dataKey="createdBy"
                  //create new array with members id and then show the mail
                  cellRenderer={({ rowData }) => (
                    <Link to={'/users?search=' + rowData.createdBy.email}>
                      {rowData.createdBy.email || 'na'}
                    </Link>
                  )}
                  width={300}
                />
                <Column
                  label="Org"
                  dataKey="orgName"
                  width={250}
                  cellRenderer={({ rowData }) => (
                    <Link to={'/orgs/' + rowData.orgId}>{rowData.orgName || rowData.orgId}</Link>
                  )}
                />
                <Column
                  label="created at"
                  dataKey="createdAt"
                  //format the date to absolute date
                  cellRenderer={({ cellData }) => format(parseISO(cellData), 'yyyy-MM-dd HH:mm')}
                  width={250}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default BlogsList
