import React, { useState } from 'react'
import DatePicker from 'react-datepicker'

import css from '../../../css/dashboard.module.css'
import Button from '../../shared/button'

function DatePickerScan(props) {
  const dateNow = new Date()
  const endDateUtc = dateNow.toISOString()
  const estimatedStartDate = new Date(dateNow.setMonth(dateNow.getMonth() - 1)).toISOString()

  const [startDate, setStartDate] = useState(new Date(estimatedStartDate))
  const [endDate, setEndDate] = useState(new Date(endDateUtc))

  const parsedStartDate = startDate.toISOString()
  const parsedEndDate = endDate.toISOString()

  // console.log('endDateUtc', endDateUtc)
  // console.log('estimatedStartDate', estimatedStartDate)

  // console.log('startDate', startDate)
  // console.log('endDate', endDate)

  // console.log('parsedEndDate', parsedEndDate)
  // console.log('parsedStartDate', parsedStartDate)

  return (
    <div className={css.datePickerWrapper}>
      <label className={css.label}>Start: </label>
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <label className={css.label}>End:</label>
      <DatePicker
        selected={endDate}
        onChange={date => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
      <label className={css.label} />
      <Button
        primary
        right
        small
        onClick={() => {
          props.reScan(parsedStartDate, parsedEndDate)
        }}
        loading={false}
      >
        Re-scan
      </Button>
    </div>
  )
}

export default DatePickerScan
