import API from "../api";

//get home data from api

export default class HomeData {
  static GetCounts = (cb) => {
    API.Get({
      path: "/admin/counts",
      success: cb,
      error: cb,
    });
  };
}
