import React, { Component } from 'react'
import { AutoSizer, Column, Table } from 'react-virtualized'
import queryString from 'query-string'
import Fuse from 'fuse.js'

import Searchbar from '../../layout/searchbar'
import Loader from '../../layout/loader'
import KeywordsList from '../../data/models/keywords'

import 'react-datepicker/dist/react-datepicker.css'
import clearWhite from '../../../images/clear_white-24px.svg'
import Add from '../../../images/add.js'
import Toastify from 'toastify-js'
import Button from '../../shared/button'

import css from '../../../css/dashboard.module.css'

const options = {
  threshold: 0.1,
  keys: ['item']
}

const blockNotice = `This item level is set to block. This will automatically ban all organizations that uses this restricted item. Are you sure you want to continue?`

class Keywords extends Component {
  state = {
    keywords: [],
    isLoading: true,
    valueKeywords: '',
    checked: false,
    level: 'flag',
    published: true,
    isStaging: localStorage.isStaging === 'true'
  }

  componentDidMount() {
    KeywordsList.GetKeywords(data => {
      const params = queryString.parse(this.props.location.search)
      const fuse = new Fuse(data, options)
      this.setState({
        keywords: data,
        isLoading: false,
        value: params.search || '',
        fuse: fuse,
        selectedKeyword: data[0].item
      })
    })
  }

  addRegex = () => {
    if (this.state.checked === false) {
      this.setState({ checked: true })
    } else {
      this.setState({ checked: false })
    }
  }

  onSearch = text => {
    this.setState({
      value: text
    })
  }

  onCreation = e => {
    this.setState({
      valueKeywords: e.target.value
    })
  }

  onKeyUp = e => {
    if (e.key === 'Enter') {
      this.createKeyword()
    }
  }

  successToast = () => {
    Toastify({
      text: 'Keywords updated!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'rgb(57, 118, 255)'
    }).showToast()
  }

  errorToast = () => {
    Toastify({
      text: 'Error!',
      offset: {
        x: 50,
        y: 30
      },
      position: 'right',
      gravity: 'bottom',
      backgroundColor: 'red'
    }).showToast()
  }

  createKeyword = () => {
    if (this.state.level === 'block') {
      if (!window.confirm(blockNotice)) {
        return
      }
    }

    if (this.state.valueKeywords) {
      const data = {
        item: this.state.checked
          ? `(?i)\\b${this.state.valueKeywords}\\b`
          : this.state.valueKeywords,
        itemType: 'content_keyword',
        level: this.state.level
      }

      KeywordsList.CreateKeyword(data, response => {
        if (!response.error) {
          let newKeywords = [...this.state.keywords]
          newKeywords.push(data)
          this.setState({
            keywords: newKeywords,
            valueKeywords: '',
            level: 'flag'
          })
          this.successToast()
        } else {
          this.errorToast()
        }
      })
    } else {
      this.errorToast()
    }
  }

  deleteBlocklistItem = keyword => {
    let item = this.state.keywords.find(k => k.item === keyword)

    if (!item) {
      this.errorToast()
      return
    }

    if (window.confirm(`You really want to delete?`)) {
      KeywordsList.DeleteKeyword(item.id, response => {
        if (!response.error) {
          let newKeywords = this.state.keywords.filter(k => k.id !== item.id)
          this.setState({ keywords: newKeywords })
          this.successToast()
        } else {
          this.errorToast()
        }
      })
    }
  }

  scanForBlocklistItem = content => {
    let item = this.state.keywords.find(k => k.item === content)

    if (!item) {
      this.errorToast()
      return
    }

    if (item.level === 'block') {
      if (!window.confirm(blockNotice)) {
        return
      }
    }

    if (window.confirm(`You really want to scan sites, pages and blogs for this keyword?`)) {
      const data = {
        published: true
      }

      KeywordsList.ScanAll(item.id, data, response => {
        if (!response.error) {
          this.successToast()
        } else {
          this.errorToast()
        }
      })
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    let data = []
    if (this.state.value !== '') {
      data = this.state.fuse.search(this.state.value)
      data = data.map(keywords => keywords.item)
    } else {
      data = this.state.keywords
    }

    const options = ['flag', 'block']

    const stagingBorder = this.state.isStaging ? '3px solid #CF55A4' : ''

    return (
      <div
        className={css.dashboard}
        style={{ borderTop: stagingBorder, borderLeft: stagingBorder }}
      >
        <Searchbar length={data.length} onSearch={this.onSearch} value={this.state.value} />
        <div className={css.keywordWrapper}>
          <Add />
          <input
            className={css.createKeyword}
            type="text"
            placeholder={'Add...'}
            onChange={this.onCreation}
            value={this.state.valueKeywords}
            onKeyUp={this.onKeyUp}
          />
          <select
            className={css.selectFilter}
            style={{ marginLeft: '5px' }}
            value={this.state.level}
            onChange={e => this.setState({ level: e.target.value })}
          >
            {options &&
              options.map((opt, i) => (
                <option key={i} value={opt}>
                  {opt}
                </option>
              ))}
          </select>
          {this.state.level === 'block' ? (
            <div className={css.blockWarning}>Be super careful!</div>
          ) : null}
          <div className={css.filterCheckbox}>
            <input type="checkbox" onClick={this.addRegex} id="checkbox" />
            <label htmlFor="checkbox" className={css.checkboxLabel}>
              (?i)\b\b
            </label>
          </div>
          <button className={css.createKeywordButton} onClick={this.createKeyword}>
            Add
          </button>
        </div>
        <div className={css.table}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                width={width}
                height={height}
                headerHeight={40}
                rowHeight={50}
                rowCount={data.length}
                rowGetter={({ index }) => data[index]}
                headerClassName={css.header}
                rowClassName={({ index }) => {
                  if (index % 2 === 0) {
                    return 'even'
                  }
                }}
              >
                <Column
                  label="Delete"
                  dataKey="item"
                  cellRenderer={({ cellData }) => (
                    <button
                      className={css.banButton}
                      onClick={() => this.deleteBlocklistItem(cellData)}
                    >
                      <img src={clearWhite} alt="clear" />
                    </button>
                  )}
                  width={50}
                />
                <Column
                  label="Item"
                  dataKey="item"
                  width={400}
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                />
                <Column
                  label="Type"
                  dataKey="itemType"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={250}
                />
                <Column
                  label="Level"
                  dataKey="level"
                  cellRenderer={({ cellData }) => <p>{cellData}</p>}
                  width={250}
                />
                <Column
                  label="Complete Scan (published)"
                  dataKey="item"
                  cellRenderer={({ cellData }) => (
                    <>
                      <Button
                        secondary={true}
                        right
                        small
                        onClick={() => {
                          this.scanForBlocklistItem(cellData)
                        }}
                        loading={false}
                      >
                        Re-scan
                      </Button>
                    </>
                  )}
                  width={250}
                />
              </Table>
            )}
          </AutoSizer>
        </div>
      </div>
    )
  }
}

export default Keywords
